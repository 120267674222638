import { KeyboardEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { UserItem } from '../types'
import { UserAvatar } from './UserAvatar'

export type MentionListProps = {
  items: UserItem[]
  command: (payload: { id: string; label: string }) => void
}

export type MentionListRef = {
  onKeyDown: (props: { event: KeyboardEvent }) => boolean
}

export const MentionList = forwardRef<MentionListRef, MentionListProps>((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0)

  const selectItem = (index: number): void => {
    const user = props.items[index]

    if (user) {
      props.command({
        id: user.id,
        label: user.name,
      })
    }
  }

  const upHandler = (): void => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length)
  }

  const downHandler = (): void => {
    setSelectedIndex((selectedIndex + 1) % props.items.length)
  }

  const enterHandler = (): void => {
    selectItem(selectedIndex)
  }

  useEffect(() => {
    setSelectedIndex(0)
  }, [props.items])

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }: { event: KeyboardEvent }): boolean => {
      if (event.key === 'ArrowUp') {
        upHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        downHandler()
        return true
      }

      if (event.key === 'Enter') {
        event.stopPropagation()
        event.preventDefault()
        enterHandler()
        return true
      }

      if (event.key === 'Tab') {
        selectItem(selectedIndex)
        return true
      }

      return false
    },
  }))

  return (
    <>
      <div className='overflow-hidden relative bg-white p-2 shadow-md border rounded-sm min-w-[8rem] outline-none transition-all'>
        {props.items.length ? (
          props.items.map((user, index) => (
            <button
              className={`w-full hover:bg-foreground/5 transition-colors outline-none text-sm py-1.5 px-2 rounded-sm items-center cursor-pointer flex ${
                index === selectedIndex ? 'bg-foreground/5' : ''
              }`}
              key={index}
              onClick={() => selectItem(index)}
            >
              <UserAvatar user={user} size='small' />
              <span className='ml-2'>{user.name}</span>
            </button>
          ))
        ) : (
          <div className='item'>No result</div>
        )}
      </div>
    </>
  )
})
