import { CSSProperties, FC } from 'react'
import { cn } from '../utils'
import { Icon } from './Icon'

type Props = {
  size?: number
  style?: CSSProperties
  className?: string
}

export const Spinner: FC<Props> = ({ size = 16, style, className }) => {
  return <Icon style={style} width={size} height={size} name='Spinner' className={cn('animate-spin', className)} />
}
