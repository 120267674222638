import { CaretSortIcon } from '@radix-ui/react-icons'
import * as React from 'react'

import { FixedSizeList } from 'react-window'
import { cn } from '../utils'
import { Button } from './Button'
import { Command, CommandEmpty, CommandEmptyList, CommandGroup, CommandInput, CommandItem } from './Command'
import { Icon } from './Icon'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { Spinner } from './Spinner'

export interface IAutoCompleteValue {
  value: string
  label: string
  component?: React.ReactNode
}

interface IAutoCompleteCreateOption {
  onCreate: (value: string) => void
  loading?: boolean
  error?: boolean
  show?: boolean
  /** @defaultValue "Create" */
  label?: string
}

type Props = {
  autocompleteValues: IAutoCompleteValue[]
  placeholder?: string | React.ReactNode
  value: string
  onSelect: (value: string) => void
  disabled?: boolean
  className?: string
  contentClassName?: string
  createOption?: IAutoCompleteCreateOption
  onOpenChange?: (open: boolean) => void
}

export function Combobox({
  autocompleteValues,
  placeholder,
  value,
  onSelect,
  disabled,
  className,
  contentClassName,
  createOption,
  onOpenChange,
}: Props) {
  const [open, setOpen] = React.useState(false)
  const [newValue, setNewValue] = React.useState('')

  const createLabel = createOption?.label ? createOption?.label : 'Create'

  const handleSelect = React.useCallback(
    (selectedValue: string) => {
      if (createOption && selectedValue === newValue) {
        createOption.onCreate(newValue)
        setOpen(false)
        setNewValue('')
        return
      }

      onSelect?.(selectedValue)
      setOpen(false)
    },
    [createOption, newValue, onSelect],
  )

  const handleOpenChange = (isOpen: boolean) => {
    setOpen(isOpen)
    onOpenChange?.(isOpen)
  }

  const getAutoCompleteSelected = React.useCallback(
    (value: string) => {
      if (createOption?.loading) {
        return {
          value: newValue,
          label: `Creating "${newValue}"...`,
          component: (
            <div className='justify-center items-center gap-1 inline-flex'>
              <Spinner />
              <span className='px-1 flex text-zinc-950 text-sm font-small leading-tight'>Creating ...</span>
            </div>
          ),
        }
      }

      return autocompleteValues.find((autocompleteValue) => autocompleteValue.value === value)
    },
    [autocompleteValues, createOption?.loading, newValue],
  )

  const getAutoCompleteComponent = React.useCallback(
    (autocompleteValue: string) => {
      const value = getAutoCompleteSelected(autocompleteValue)
      return value?.component ?? value?.label
    },
    [getAutoCompleteSelected],
  )

  const autoCompleteOptions = React.useMemo(() => {
    // Is the create option enabled and not error flag restoring the previous options.
    const isCreateOptionEnabled = createOption && !createOption.error

    // if there is a value to create or there are no autocomplete values, show the create option
    const showCreateOption = newValue?.length || autocompleteValues.length === 0 || createOption?.show

    // if option new already exists, don't show the create option
    const optionNoExists = !autocompleteValues.find((autocompleteValue) => autocompleteValue.value === newValue)

    const createNewLabel = newValue?.length ? `${createLabel} "${newValue}"` : `${createLabel} new ...`

    return [
      ...autocompleteValues,
      ...(isCreateOptionEnabled && showCreateOption && optionNoExists
        ? [
            {
              value: newValue,
              label: createNewLabel,
              component: (
                <button className='justify-center items-center gap-1 inline-flex'>
                  <Icon name='Plus' className='h-4 w-4' />
                  <span className='px-1 flex text-zinc-950 text-sm font-medium leading-tight'>{createNewLabel}</span>
                </button>
              ),
            },
          ]
        : []),
    ].filter((option) => option.label.toLowerCase().includes(newValue.toLowerCase()))
  }, [autocompleteValues, createLabel, createOption, newValue])

  const isFilledClass = value ? '' : 'text-muted-foreground'

  return (
    <Popover open={open} onOpenChange={handleOpenChange}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant='input'
          role='combobox'
          aria-expanded={open}
          className={cn('justify-between font-normal w-[200px]', className)}
        >
          <span className={isFilledClass}>
            {value || createOption?.loading ? getAutoCompleteComponent(value) : placeholder}
          </span>
          <CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn('w-full p-0', contentClassName)} align='start'>
        <Command shouldFilter={false}>
          <CommandInput placeholder={placeholder as string} className='h-9' onValueChange={setNewValue} />
          {!autoCompleteOptions.length ? (
            <CommandEmptyList />
          ) : (
            <>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                <FixedSizeList
                  width='100%'
                  height={autoCompleteOptions.length * 35 > 250 ? 250 : autoCompleteOptions.length * 35}
                  itemCount={autoCompleteOptions.length}
                  itemSize={35}
                >
                  {({ index, style }) => {
                    return (
                      <CommandItem
                        style={style}
                        key={autoCompleteOptions[index].value}
                        value={autoCompleteOptions[index].label.toLocaleLowerCase()}
                        onSelect={() => handleSelect(autoCompleteOptions[index].value)}
                      >
                        {autoCompleteOptions[index].component ?? autoCompleteOptions[index].label}
                      </CommandItem>
                    )
                  }}
                </FixedSizeList>
              </CommandGroup>
            </>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}
