import { FC } from 'react'
import { cn } from '../utils'
import { Flexbox } from './layout'

type Props = {
  steps: number
  currentStep: number
}

const SHARED_STYLES = 'w-2 h-2 rounded-full'
const DOT_GAP = 1

export const FormStep: FC<Props> = ({ steps, currentStep }) => {
  return (
    <Flexbox gap={DOT_GAP} className='relative'>
      {Array.from({ length: steps }).map((_, index) => (
        <div key={index} className={cn(SHARED_STYLES, 'bg-background-3')} />
      ))}

      <div
        className={cn(SHARED_STYLES, 'bg-primary absolute top-0 left-0')}
        style={{ width: `calc(${(currentStep / steps) * 100}% - ${(DOT_GAP * 4) / 2}px)` }}
      />
    </Flexbox>
  )
}
