import { ComponentProps, FC, ReactNode } from 'react'
import { cn } from '../../utils'
import { Flexbox } from '../layout'
import { SheetContent, SheetFooter, SheetHeader, Sheet as SheetRoot, SheetTrigger } from './primitives'

type SheetProps = {
  trigger?: ReactNode
  side?: ComponentProps<typeof SheetContent>['side']
  plain?: boolean
  headerActions?: ReactNode
  footer?: ReactNode
} & ComponentProps<typeof SheetHeader> &
  ComponentProps<typeof SheetRoot>

export const Sheet: FC<SheetProps> = ({
  trigger,
  title,
  description,
  headerActions,
  footer,
  side,
  plain,
  children,
  className,
  ...rest
}) => {
  return (
    <SheetRoot {...rest}>
      <SheetTrigger>{trigger}</SheetTrigger>
      <SheetContent side={side} className={className}>
        {(title || description) && (
          <SheetHeader title={title} description={description}>
            {headerActions}
          </SheetHeader>
        )}
        <Flexbox justify='between' height='full' className='overflow-hidden max-h-100%' column grow>
          <Flexbox gap={4} className={cn('overflow-y-scroll overflow-x-hidden', { 'px-6 pt-4': !plain })} column grow>
            {children}
          </Flexbox>
          {footer && <SheetFooter>{footer}</SheetFooter>}
        </Flexbox>
      </SheetContent>
    </SheetRoot>
  )
}
