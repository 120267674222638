import * as SheetPrimitive from '@radix-ui/react-dialog'
import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'
import { cn } from '../../utils'
import { Icon } from '../Icon'
import { IconButton } from '../IconButton'
import { Flexbox } from '../layout'
import { Separator } from '../Separator'
import { Heading } from '../typography'

const Sheet = SheetPrimitive.Root

const SheetTrigger = SheetPrimitive.Trigger

const SheetClose = SheetPrimitive.Close

const SheetPortal = SheetPrimitive.Portal

const SheetOverlay = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className,
    )}
    {...props}
    ref={ref}
  />
))
SheetOverlay.displayName = SheetPrimitive.Overlay.displayName

const sheetVariants = cva(
  [
    'pb-6',
    'pt-4',
    'relative',
    'fixed',
    'z-50',
    'rounded-md',
    'border-none',
    'bg-background',
    'shadow-sheet',
    'transition',
    'data-[state=open]:animate-in',
    'data-[state=closed]:animate-out',
    'data-[state=closed]:duration-150',
    'data-[state=open]:duration-200',
  ],
  {
    variants: {
      side: {
        top: [
          'inset-x-0',
          'top-0',
          'border-b',
          'data-[state=closed]:slide-out-to-top',
          'data-[state=open]:slide-in-from-top',
        ],
        bottom: [
          'inset-x-0',
          'bottom-0',
          'border-t',
          'data-[state=closed]:slide-out-to-bottom',
          'data-[state=open]:slide-in-from-bottom',
        ],
        left: [
          'inset-y-4',
          'left-4',
          'h-auto',
          'w-3/4',
          'border-r',
          'data-[state=closed]:slide-out-to-left',
          'data-[state=open]:slide-in-from-left',
          'sm:max-w-[600px]',
        ],
        right: [
          'inset-y-4',
          'right-4',
          'h-auto',
          'w-3/4',
          '',
          'border-l',
          'data-[state=closed]:slide-out-to-right',
          'data-[state=open]:slide-in-from-right',
          'sm:max-w-[600px]',
        ],
      },
    },
    defaultVariants: {
      side: 'right',
    },
  },
)

interface SheetContentProps
  extends React.ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = React.forwardRef<React.ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ side = 'right', className, children, ...props }, ref) => (
    <SheetPortal>
      <SheetOverlay />
      <Flexbox column asChild>
        <SheetPrimitive.Content ref={ref} className={cn(sheetVariants({ side }), className)} {...props}>
          {children}

          <SheetClose className='absolute top-4 right-4' asChild>
            <IconButton size='sm'>
              <Icon name='Close' size={20} />
            </IconButton>
          </SheetClose>
        </SheetPrimitive.Content>
      </Flexbox>
    </SheetPortal>
  ),
)
SheetContent.displayName = SheetPrimitive.Content.displayName

type SheetHeaderProps = {
  title?: string
  description?: string
} & React.ComponentProps<typeof Flexbox>

const SheetHeader: React.FC<SheetHeaderProps> = ({ title, description, className, children, ...props }) => (
  <Flexbox gap={4} column>
    <Flexbox gap={2} justify='between' align='start' className={cn('px-6 pr-16', className)} {...props}>
      <Flexbox justify='center' height='full' column grow>
        {title && (
          <Heading level={3} weight='medium'>
            {title}
          </Heading>
        )}
        {description && (
          <Heading level={4} color='mutedForeground'>
            {description}
          </Heading>
        )}
      </Flexbox>
      {children}
    </Flexbox>
    <Separator />
  </Flexbox>
)
SheetHeader.displayName = 'SheetHeader'

const SheetFooter = ({ className, ...props }: React.ComponentProps<typeof Flexbox>) => (
  <div>
    <SheetDivider />
    <Flexbox width='full' gap={2} className={cn('px-6 pt-4', className)} {...props} column />
  </div>
)
SheetFooter.displayName = 'SheetFooter'

const SheetTitle = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ children, ...props }, ref) => (
  <SheetPrimitive.Title asChild ref={ref} {...props}>
    <Heading level={3}>{children}</Heading>
  </SheetPrimitive.Title>
))
SheetTitle.displayName = SheetPrimitive.Title.displayName

const SheetDescription = React.forwardRef<
  React.ElementRef<typeof SheetPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} />
))
SheetDescription.displayName = SheetPrimitive.Description.displayName

const SheetDivider = () => <Separator className='-ml-6 -mr-6 w-auto' />

export {
  Sheet,
  SheetClose,
  SheetContent,
  SheetDescription,
  SheetDivider,
  SheetFooter,
  SheetHeader,
  SheetOverlay,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
}
