import { IconColor, getRandomIcon } from '@thenarrative/common'
import { VariantProps, cva } from 'class-variance-authority'
import { useMemo } from 'react'
import { cn } from '../utils'
import { PhosphorIcon, PhosphorIconName, PhosphorIconNames } from './PhosphorIcon'
import { Flexbox } from './layout'

type Props = {
  icon: string
  backgroundColor?: string
}

const variants = cva('w-8 h-8 rounded-lg', {
  variants: {
    color: {
      amber: 'bg-amber-300 text-amber-1100',
      blue: 'bg-blue-300 text-blue-1100',
      bronze: 'bg-bronze-300 text-bronze-1100',
      cyan: 'bg-cyan-300 text-cyan-1100',
      gold: 'bg-gold-300 text-gold-1100',
      green: 'bg-green-300 text-green-1100',
      indigo: 'bg-indigo-300 text-indigo-1100',
      mauve: 'bg-mauve-300 text-mauve-1100',
      mint: 'bg-mint-300 text-mint-1100',
      olive: 'bg-olive-300 text-olive-1100',
      orange: 'bg-orange-300 text-orange-1100',
      pink: 'bg-pink-300 text-pink-1100',
      plum: 'bg-plum-300 text-plum-1100',
      purple: 'bg-purple-300 text-purple-1100',
      red: 'bg-red-300 text-red-1100',
      sky: 'bg-sky-300 text-sky-1100',
      violet: 'bg-violet-300 text-violet-1100',
      yellow: 'bg-yellow-300 text-yellow-1100',
    },
  },
})

type Color = VariantProps<typeof variants>['color']

export const ProjectIcon: React.FC<Props> = ({ icon, backgroundColor }) => {
  const { iconName, color } = useMemo(() => {
    const { icon: randomIcon, iconColor: randomColor } = getRandomIcon()

    const iconName = (PhosphorIconNames.includes(icon) ? icon : randomIcon) as PhosphorIconName
    const color = (
      backgroundColor && Object.values(IconColor).includes(backgroundColor as IconColor) ? backgroundColor : randomColor
    ) as Color

    return {
      iconName,
      color,
    }
  }, [backgroundColor, icon])

  return (
    <Flexbox align='center' justify='center' className={cn(variants({ color }))}>
      <PhosphorIcon name={iconName} />
    </Flexbox>
  )
}
