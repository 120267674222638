import type { ToggleGroupItemProps } from '@radix-ui/react-toggle-group'
import * as RadixToggleGroup from '@radix-ui/react-toggle-group'
import { FC, forwardRef } from 'react'
import { cn } from '../utils'

const toggleGroupClassName = 'flex rounded-lg border border-gray-100 bg-background-2 p-1'

const ToggleGroup = forwardRef<
  HTMLDivElement,
  RadixToggleGroup.ToggleGroupSingleProps | RadixToggleGroup.ToggleGroupMultipleProps
>((props, ref) => {
  return (
    <RadixToggleGroup.Root ref={ref} {...props} className={cn(toggleGroupClassName, props.className)}>
      {props.children}
    </RadixToggleGroup.Root>
  )
})

const itemsClassName =
  "grow rounded-md py-2 px-3 text-foreground-2 text-sm data-[state='on']:bg-white data-[state='on']:text-foreground-2 data-[state='on']:shadow-md"

const ToggleGroupItem: FC<ToggleGroupItemProps> = ({ value, children, className, ...props }) => (
  <RadixToggleGroup.Item className={cn(itemsClassName, className)} value={value} {...props}>
    {children}
  </RadixToggleGroup.Item>
)

export { ToggleGroup, ToggleGroupItem }
