import { VariantProps, cva } from 'class-variance-authority'
import { PropsWithChildren, forwardRef } from 'react'
import { cn } from '../../utils'
import { Slot } from '../Slot'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Tooltip'

const textVariants = cva(undefined, {
  variants: {
    size: {
      inherit: 'text-inherit-size',
      xs: 'text-xs',
      sm: 'text-sm',
      caption: 'text-[13px]',
      md: 'text-base',
      base: 'text-base',
      lg: 'text-lg',
      xl: 'text-xl',
      '2xl': 'text-2xl',
      '3xl': 'text-3xl',
      '4xl': 'text-4xl',
      '5xl': 'text-5xl',
    },
    weight: {
      light: 'font-light',
      regular: 'font-normal',
      medium: 'font-medium',
      semibold: 'font-semibold',
      bold: 'font-bold',
      extrabold: 'font-extrabold',
      black: 'font-black',
    },
    color: {
      interactive: 'text-interactive',
      inherit: 'text-inherit',
      primary: 'text-primary',
      secondary: 'text-secondary-foreground',
      foreground: 'text-foreground',
      'foreground-2': 'text-foreground-2',
      background: 'text-background',
      destructive: 'text-destructive',
      warning: 'text-warning',
      accent: 'text-accent-foreground',
      muted: 'text-muted-foreground',
      primaryForeground: 'text-primary-foreground',
      mutedForeground: 'text-muted-foreground',
      secondaryForeground: 'text-secondary-foreground',
      blue: 'text-blue',
      badge: 'text-badge',
    },
    lineHeight: {
      none: 'leading-none',
      tight: 'leading-tight',
      snug: 'leading-snug',
      normal: 'leading-normal',
      relaxed: 'leading-relaxed',
      loose: 'leading-loose',
      badge: 'leading-[20px]',
      8: 'leading-8',
    },
    align: {
      left: 'text-left',
      center: 'text-center',
      right: 'text-right',
      justify: 'text-justify',
    },
    transform: {
      uppercase: 'uppercase',
      lowercase: 'lowercase',
      capitalize: 'capitalize',
      'normal-case': 'normal-case',
    },
    cursor: {
      default: 'cursor-default',
    },
    truncate: {
      true: 'truncate',
    },
    ellipsis: {
      true: 'text-ellipsis',
    },
    letterSpacing: {
      normal: 'tracking-normal',
      tight: 'tracking-tight',
      widest: 'tracking-widest',
    },
  },
  defaultVariants: {
    size: 'base',
    color: 'inherit',
    weight: 'regular',
    align: 'left',
    transform: 'normal-case',
    lineHeight: 'normal',
  },
})

export type TextProps = PropsWithChildren<{
  className?: string
  asChild?: boolean
  tooltip?: boolean
  as?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div' | 'code'
}> &
  VariantProps<typeof textVariants> &
  React.HTMLAttributes<HTMLParagraphElement>

const Text = forwardRef<HTMLParagraphElement, TextProps>((props, forwardedRef) => {
  const {
    asChild,
    children,
    size,
    color,
    align,
    transform,
    weight,
    lineHeight,
    cursor,
    className,
    truncate,
    ellipsis,
    letterSpacing,
    tooltip,
    ...rest
  } = props

  const Component = asChild ? Slot : props.as ?? 'span'

  // Safeguard against passing `as` to a child slot component
  if ('as' in rest) {
    delete rest.as
  }

  return (
    <Component
      ref={forwardedRef}
      className={cn(
        textVariants({
          size,
          color,
          weight,
          align,
          transform,
          lineHeight,
          cursor,
          truncate,
          ellipsis,
          letterSpacing,
        }),
        className,
      )}
      {...rest}
    >
      {tooltip && truncate ? (
        <TooltipProvider delayDuration={750}>
          <Tooltip>
            <TooltipTrigger asChild>
              <span className='truncate'>{children}</span>
            </TooltipTrigger>
            <TooltipContent>{children}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <>{children}</>
      )}
    </Component>
  )
})
Text.displayName = 'Text'

export { Text, textVariants }
