import createPinoLogger, { Logger as PinoLogger } from 'pino'

const logger = createPinoLogger()

export class Logger {
  private constructor(private readonly logger: PinoLogger) {}

  public info(message: string, ...args: any[]): void {
    this.logger.info(message, ...args)
  }

  public error(message: string, ...args: any[]): void {
    this.logger.error(message, ...args)
  }

  public static create(name: string): Logger {
    return new Logger(logger.child({ name }))
  }
}
