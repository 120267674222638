import * as React from 'react'

import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils'
import { Flexbox } from './layout'

const config = cva(undefined, {
  variants: {
    containerSize: {
      sm: 'h-input-sm',
      md: 'h-input-md',
    },
  },
  defaultVariants: {
    containerSize: 'md',
  },
})

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  prepend?: React.ReactNode
  prependProps?: React.HTMLAttributes<HTMLSpanElement>
  append?: React.ReactNode
  appendProps?: React.HTMLAttributes<HTMLSpanElement>
  withoutFocusStyles?: boolean
} & VariantProps<typeof config>

const containerStyles = [
  'flex',
  'w-full',
  'items-center',
  'rounded-md',
  'border',
  'border-input',
  'bg-background',
  'px-3',
  'py-2',
  'text-sm',
  'bg-background',
  'hover:[&:not(:focus-within)]:border-input-hover',
  'shadow-input',
]

const focusStyles = ['focus-within:ring-2', 'focus-within:ring-[#ff0000]', 'focus-within:ring-offset-2']

const _noFocusStyles = ['focus-within:outline-none', 'focus-within:ring-0', 'focus-within:ring-offset-0']

const _inputStyles = [
  'leading-none',
  'file:border-0',
  'file:bg-transparent',
  'file:text-sm',
  'file:font-medium',
  'placeholder:text-muted-foreground',
  'placeholder:font-normal',
  'disabled:cursor-not-allowed',
  'disabled:opacity-50',
]
const _focusStyles = ['focus-within:ring-2', 'focus-within:input-ring', 'focus-within:border-input-active']

export const inputStyles = cn(containerStyles, _inputStyles, focusStyles)

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, prepend, prependProps, append, appendProps, type, withoutFocusStyles, containerSize, ...props },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null)

    React.useImperativeHandle(ref, () => inputRef.current!, [inputRef])

    const handleOnParentClick = React.useCallback(() => {
      inputRef.current?.focus()
    }, [])

    return (
      <Flexbox
        gap={2}
        align='center'
        width='full'
        className={cn(containerStyles, config({ containerSize }), !withoutFocusStyles && _focusStyles, className)}
        onClick={handleOnParentClick}
      >
        {prepend !== undefined && <span {...prependProps}>{prepend}</span>}
        <input
          className={cn(_inputStyles, _noFocusStyles, 'w-full', className)}
          type={type}
          ref={inputRef}
          {...props}
        />
        {append !== undefined && <span {...appendProps}>{append}</span>}
      </Flexbox>
    )
  },
)

Input.displayName = 'Input'

export { Input }
