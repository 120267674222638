import * as SeparatorPrimitive from '@radix-ui/react-separator'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '../utils'

const variants = cva('shrink-0 bg-border', {
  variants: {
    orientation: {
      horizontal: 'h-[1px] w-full',
      vertical: 'h-full w-[1px]',
    },
    color: {
      default: 'bg-border',
      light: 'bg-slate-300',
    },
  },
  defaultVariants: {
    color: 'default',
    orientation: 'horizontal',
  },
})

type Props = ComponentPropsWithoutRef<typeof SeparatorPrimitive.Root> & VariantProps<typeof variants>

const Separator = forwardRef<ElementRef<typeof SeparatorPrimitive.Root>, Props>(
  ({ className, orientation = 'horizontal', decorative = true, color = 'default', ...props }, ref) => (
    <SeparatorPrimitive.Root
      ref={ref}
      decorative={decorative}
      orientation={orientation}
      className={cn(variants({ orientation, color }), className)}
      {...props}
    />
  ),
)
Separator.displayName = SeparatorPrimitive.Root.displayName

export { Separator }
