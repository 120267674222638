import { FC } from 'react'
import { Spinner } from './Spinner'

export const FullScreenSpinner: FC<React.PropsWithChildren> = () => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-background z-50">
      <div className="opacity-0 animate-[fade-in_0.3s_3s_ease-in-out_forwards]">
        <Spinner size={24} />
      </div>
    </div>
  )
}
