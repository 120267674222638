export const ErrorLayout = () => {
  return (
    <div className='flex flex-col items-center justify-center h-full w-full gap-4'>
      <h1 className='text-6xl'>Oops, something went wrong</h1>
      <div className='text-lg'>Our team has been notified of the issue and we are fixing it.</div>
      <div className='text-sm text-muted-foreground'>
        In the meantime, try refreshing the page or going to the homepage
      </div>
      <div className='flex items-center justify-center w-full gap-4 mt-6'>
        <button
          className='border border-input bg-background hover:bg-accent hover:text-accent-foreground py-1 px-2'
          onClick={() => window.location.reload()}
        >
          Refresh
        </button>
        <button
          className='border border-input bg-background hover:bg-accent hover:text-accent-foreground py-1 px-2'
          onClick={() => (window.location.href = '/')}
        >
          Home
        </button>
      </div>
    </div>
  )
}
