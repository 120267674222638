import { VariantProps, cva } from 'class-variance-authority'
import { FC, PropsWithChildren } from 'react'
import { cn } from '../../utils'
import { Text, TextProps, textVariants } from './Text'

const heading = cva(['font-semibold'], {
  variants: {
    level: {
      1: ['text-3xl', 'leading-18'],
      2: ['text-2xl', 'leading-13'],
      3: ['text-lg', 'leading-10.5'],
      4: ['text-md', 'leading-9.5'],
      5: ['text-sm', 'leading-8'],
      6: ['text-xs', 'leading-7.5'],
    },
  },
  defaultVariants: {
    level: 1,
  },
})

export type HeadingProps = VariantProps<typeof heading> &
  Pick<TextProps, 'color' | 'size' | 'weight' | 'lineHeight'> &
  React.HTMLAttributes<HTMLHeadingElement>

const Heading: FC<PropsWithChildren<HeadingProps>> = ({
  level,
  children,
  color,
  size,
  weight = 'medium',
  lineHeight,
  className,
  ...rest
}) => {
  return (
    <Text
      as={level ? `h${level}` : undefined}
      className={cn(textVariants({ size, weight, lineHeight, color }), heading({ level }), className)}
      {...rest}
    >
      {children}
    </Text>
  )
}

export { Heading }
