import ContentLoader from 'react-content-loader'

export const CircleLoader = ({ size, title }: { size: number, title: string }) => {
  return (
    <ContentLoader
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
    >
      <circle cx={size / 2} cy={size / 2} r={size / 2} />
    </ContentLoader>
  )
}

export const RectLoader = ({ width, height, title }: { width: number, height: number, title: string }) => {
  return (
    <ContentLoader
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
    >
      <rect x="0" y="0" width={width} height={height} />
    </ContentLoader>
  )
}

/** Automatically fills 100% of the parent container */
export const BackgroundLoader = () => {
  return (
    <ContentLoader
      className="rounded-sm"
      width="100%"
      height="100%"
    >
      <rect x="0" y="0" width="100%" height="100%" />
    </ContentLoader>
  )
}
