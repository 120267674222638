import * as React from 'react'
import ReactInputMask, { Props as ReactInputMaskProps } from 'react-input-mask'
import { Input, InputProps } from './Input'

export type InputMaskProps = InputProps & Omit<ReactInputMaskProps, 'inputRef'>

const InputMask = React.forwardRef<HTMLInputElement, InputMaskProps>((props, ref) => {
  return (
    <ReactInputMask inputRef={ref} {...props}>
      {(inputProps: InputMaskProps) => <Input {...inputProps} ref={ref} />}
    </ReactInputMask>
  )
})

InputMask.displayName = 'InputMask'

export { InputMask }
