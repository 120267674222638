import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { load as loadIntercom, boot as bootIntercom, update as updateIntercom } from './intercom'

const INTERCOM_BLACKLIST_ROUTES = [
  '/[teamUrl]/[space]/[project]/[asset]'
]

export const IntercomProvider = ({ children }: any) => {
  const { pathname } = useRouter()

  if (typeof window !== 'undefined') {
    loadIntercom()
    bootIntercom()
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && INTERCOM_BLACKLIST_ROUTES.includes(pathname)) {
      updateIntercom({ hide_default_launcher: true })
    } else {
      updateIntercom({ hide_default_launcher: false })
    }
  }, [pathname])

  return children
}
