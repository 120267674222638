import { useState } from 'react'
import { Button, ButtonProps } from './Button'
import { PhosphorIcon } from './PhosphorIcon'

interface CopyButtonProps {
  className?: string
  delay?: number
  size?: ButtonProps['size']
  text: string
}

export function CopyButton({ className, text, size = 'default', delay = 2000 }: CopyButtonProps) {
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), delay)
  }

  return (
    <Button className={className} onClick={handleCopy} variant='outline' size={size}>
      <PhosphorIcon name={copied ? 'CheckCircle' : 'ClipboardText'} />
    </Button>
  )
}
