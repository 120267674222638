import { RefObject, useEffect } from 'react'

export function useClickAway<E extends HTMLElement>(
  ref: RefObject<E> | RefObject<E>[],
  listener: (event: MouseEvent) => void,
  deps: React.DependencyList = [],
) {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (Array.isArray(ref)) {
        if (
          ref
            .filter((refElement) => refElement.current != null)
            .every((refElement) => refElement.current && !refElement.current.contains(event.target as Node))
        ) {
          listener(event)
          return
        }
      } else if (ref.current && !ref.current.contains(event.target as Node)) {
        listener(event)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, deps, listener])
}
