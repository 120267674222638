import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../hooks/useDebounce'

interface ResizableProps {
  children: React.ReactNode
  minWidth?: number
  maxWidth?: number
  minHeight?: number
  maxHeight?: number
  defaultWidth?: number
  defaultHeight?: number
  onResize?: (size: { width: number; height: number }) => void
}

const RESIZE_EDGES = ['n', 'e', 's', 'w', 'ne', 'nw', 'se', 'sw']
const EDGE_SIZE = 16

export const Resizable = forwardRef<HTMLDivElement, ResizableProps>(
  (
    {
      children,
      minWidth = 100,
      maxWidth = Infinity,
      minHeight = 100,
      maxHeight = Infinity,
      defaultWidth = 200,
      defaultHeight = 200,
      onResize,
    },
    ref,
  ) => {
    const [size, setSize] = useState({ width: defaultWidth, height: defaultHeight })
    const resizingRef = useRef(false)
    const startPosRef = useRef<{ x: number; y: number }>({ x: 0, y: 0 })
    const currentEdgeRef = useRef<string>('')

    useEffect(() => {
      setSize({ width: defaultWidth, height: defaultHeight })
    }, [defaultWidth, defaultHeight])

    const handleMouseDown = useCallback(
      (edge: string) => (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        resizingRef.current = true
        startPosRef.current = { x: e.clientX, y: e.clientY }
        currentEdgeRef.current = edge
      },
      [],
    )

    const handleMouseMove = useCallback(
      (e: MouseEvent) => {
        if (!resizingRef.current) return

        const deltaX = e.clientX - startPosRef.current.x
        const deltaY = e.clientY - startPosRef.current.y

        setSize((prevSize) => {
          let newWidth = prevSize.width
          let newHeight = prevSize.height

          if (currentEdgeRef.current.includes('e')) newWidth += deltaX
          if (currentEdgeRef.current.includes('w')) newWidth -= deltaX
          if (currentEdgeRef.current.includes('s')) newHeight += deltaY
          if (currentEdgeRef.current.includes('n')) newHeight -= deltaY

          newWidth = Math.min(Math.max(newWidth, minWidth), maxWidth)
          newHeight = Math.min(Math.max(newHeight, minHeight), maxHeight)

          return { width: newWidth, height: newHeight }
        })

        startPosRef.current = { x: e.clientX, y: e.clientY }
      },
      [minWidth, maxWidth, minHeight, maxHeight],
    )

    const handleMouseUp = useCallback(() => {
      resizingRef.current = false
    }, [])

    useEffect(() => {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
      return () => {
        document.removeEventListener('mousemove', handleMouseMove)
        document.removeEventListener('mouseup', handleMouseUp)
      }
    }, [handleMouseMove, handleMouseUp])

    useDebounce(() => {
      onResize?.(size)
    }, 100)

    return (
      <div
        ref={ref}
        className='relative overflow-visible'
        style={{
          width: `${size.width}px`,
          height: `${size.height}px`,
        }}
      >
        {children}
        {RESIZE_EDGES.map((edge) => {
          const isVertical = ['n', 's'].includes(edge)
          const isHorizontal = ['w', 'e'].includes(edge)
          const isCorner = ['nw', 'ne', 'sw', 'se'].includes(edge)

          return (
            <div
              key={edge}
              onMouseDown={handleMouseDown(edge)}
              style={{
                position: 'absolute',
                [edge.includes('n') ? 'top' : 'bottom']: '0',
                [edge.includes('w') ? 'left' : 'right']: '0',
                width: isCorner ? `${EDGE_SIZE}px` : isHorizontal ? `${EDGE_SIZE}px` : '100%',
                height: isCorner ? `${EDGE_SIZE}px` : isVertical ? `${EDGE_SIZE}px` : '100%',
                cursor: `${edge}-resize`,
              }}
            />
          )
        })}
      </div>
    )
  },
)

Resizable.displayName = 'Resizable'
