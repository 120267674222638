import { CaretSortIcon, CheckIcon } from '@radix-ui/react-icons'
import * as React from 'react'
import { cn } from '../utils'
import { Button } from './Button'
import { Command, CommandEmpty, CommandEmptyList, CommandGroup, CommandInput, CommandItem } from './Command'
import { Icon } from './Icon'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { Spinner } from './Spinner'

interface IAutoCompleteValue {
  value: string
  label: string
  component?: React.ReactNode
}

interface IAutoCompleteCreateOption {
  onCreate: (value: string) => void
  loading?: boolean
  error?: boolean
  show?: boolean
  /** @defaultValue "Create" */
  label?: string
}

type Props = {
  autocompleteValues: IAutoCompleteValue[]
  placeholder?: string | React.ReactNode
  values: { value: string; label: string }[]
  onSelect: (values: { value: string; label: string }[]) => void
  disabled?: boolean
  className?: string
  createOption?: IAutoCompleteCreateOption
  trigger?: React.ReactNode
}

export function ComboboxMultiselect({
  autocompleteValues,
  placeholder,
  values,
  onSelect,
  disabled,
  className,
  createOption,
  trigger,
}: Props) {
  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')
  const [selectedValues, setSelectedValues] = React.useState<{ value: string; label: string }[]>(values || [])
  const createLabel = createOption?.label ? createOption?.label : 'Create'

  const handleSelect = React.useCallback(
    (selectedValue: { value: string; label: string }) => {
      if (createOption && selectedValue.value === inputValue) {
        createOption.onCreate(inputValue)
        setInputValue('')
        return
      }

      setSelectedValues((prevValues) =>
        prevValues.some((value) => value.value === selectedValue.value)
          ? prevValues.filter((value) => value.value !== selectedValue.value)
          : [...prevValues, selectedValue],
      )
    },
    [createOption, inputValue],
  )

  const getAutoCompleteSelected = React.useCallback(
    (value: string) => {
      if (createOption?.loading && value === inputValue) {
        return {
          value: inputValue,
          label: `Creating "${inputValue}"...`,
          component: (
            <div className='justify-center items-center gap-1 inline-flex'>
              <Spinner />
              <span className='px-1 flex text-zinc-950 text-sm font-small leading-tight'>Creating ...</span>
            </div>
          ),
        }
      }

      return autocompleteValues.find((autocompleteValue) => autocompleteValue.value === value)
    },
    [autocompleteValues, createOption?.loading, inputValue],
  )

  const getAutoCompleteComponent = React.useCallback(
    (autocompleteValue: { value: string; label: string }) => {
      const value = getAutoCompleteSelected(autocompleteValue.value)
      return value?.component ?? value?.label
    },
    [getAutoCompleteSelected],
  )

  const autoCompleteOptions = React.useMemo(() => {
    const isCreateOptionEnabled = createOption && !createOption.error
    const showCreateOption = inputValue?.length || autocompleteValues.length === 0 || createOption?.show
    const optionNoExists = !autocompleteValues.find((autocompleteValue) => autocompleteValue.value === inputValue)

    const createNewLabel = inputValue?.length ? `${createLabel} "${inputValue}"` : `${createLabel} new ...`

    return [
      ...autocompleteValues,
      ...(isCreateOptionEnabled && showCreateOption && optionNoExists
        ? [
            {
              value: inputValue,
              label: createNewLabel,
              component: (
                <div className='justify-center items-center gap-1 inline-flex'>
                  <Icon name='Plus' className='h-4 w-4' />
                  <span className='px-1 flex text-zinc-950 text-sm font-medium leading-tight'>{createNewLabel}</span>
                </div>
              ),
            },
          ]
        : []),
    ]
  }, [autocompleteValues, createLabel, createOption, inputValue])

  React.useEffect(() => {
    onSelect?.(selectedValues)
  }, [onSelect, selectedValues])

  const isFilledClass = values.length > 0 ? '' : 'text-muted-foreground'

  return (
    <Popover open={open} onOpenChange={setOpen}>
      {!trigger && (
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant='outline'
            role='combobox'
            aria-expanded={open}
            className={cn('justify-between font-normal w-[200px]', className)}
          >
            <span className={isFilledClass}>
              {selectedValues.length
                ? selectedValues.map((value) => getAutoCompleteComponent(value)).join(', ')
                : placeholder}
            </span>
            <CaretSortIcon className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </Button>
        </PopoverTrigger>
      )}

      {trigger && <PopoverTrigger asChild>{trigger}</PopoverTrigger>}
      <PopoverContent className='w-full p-0' align='start'>
        <Command>
          <CommandInput placeholder={placeholder as string} className='h-9' onValueChange={setInputValue} />
          {!autoCompleteOptions.length ? (
            <CommandEmptyList />
          ) : (
            <>
              <CommandEmpty>No results found.</CommandEmpty>
              <CommandGroup>
                {autoCompleteOptions.map((autocompleteValue) => (
                  <CommandItem
                    key={autocompleteValue.value}
                    value={autocompleteValue.label.toLocaleLowerCase()}
                    onSelect={() => handleSelect(autocompleteValue)}
                  >
                    {autocompleteValue.component ?? autocompleteValue.label}
                    <CheckIcon
                      className={cn(
                        'ml-auto h-4 w-4',
                        selectedValues.some((value) => value.value === autocompleteValue.value)
                          ? 'opacity-100'
                          : 'opacity-0',
                      )}
                    />
                  </CommandItem>
                ))}
              </CommandGroup>
            </>
          )}
        </Command>
      </PopoverContent>
    </Popover>
  )
}
