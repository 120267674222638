import { AvatarFallback, AvatarRoot } from '../components'
import { UserItem } from '../types'
import { AvatarStack } from './Avatar'
import { UserAvatar } from './UserAvatar'

type Props = {
  users: UserItem[]
  size?: 'small' | 'medium'
  className?: string
}

export const UsersAvatars = ({ users, className, size = 'medium' }: Props) => {
  return users?.length ? (
    <AvatarStack className={className}>
      {users.slice(0, 3).map((user) => (
        <UserAvatar key={user.id} user={user} size={size} />
      ))}
      {users.length > 3 && (
        <AvatarRoot className='inline-flex items-center justify-center overflow-hidden w-8 h-8'>
          <AvatarFallback className='flex bg-border text-foreground font-medium uppercase rounded-full w-full h-full items-center justify-center'>
            +{users.length - 3}
          </AvatarFallback>
        </AvatarRoot>
      )}
    </AvatarStack>
  ) : null
}
