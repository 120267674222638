import CharacterCount from '@tiptap/extension-character-count'
import Link from '@tiptap/extension-link'
import { Mention, MentionOptions } from '@tiptap/extension-mention'
import Placeholder from '@tiptap/extension-placeholder'
import Underline from '@tiptap/extension-underline'
import {
  EditorContent,
  EditorEvents,
  EditorOptions,
  Extension,
  JSONContent,
  ReactRenderer,
  useEditor,
} from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { DependencyList } from 'react'
import tippy from 'tippy.js'
import { MentionList, MentionListProps, MentionListRef } from '../components/MentionList'
import { UserItem } from '../types'
import { cn } from '../utils'

export { Editor } from '@tiptap/core'

interface CustomEditorOptions extends EditorOptions {
  placeholder?: string
  allowMentions?: boolean
  allowFiles?: boolean
  mentionSuggestions?: UserItem[]
  editorClassName?: string
  characterLimit?: number
  onEnter?: (props: EditorEvents['create']) => void
}

// we use this as the base for all text editors
function useTextEditor(
  options: Partial<EditorOptions> & {
    editorClassName?: string
  },
  deps?: DependencyList,
) {
  const { extensions, editorProps, injectCSS = false, editorClassName, ...additionalOptions } = options

  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          heading: {
            levels: [1, 2],
            HTMLAttributes: {
              class: 'font-medium text-base mb-1 text-gray-700',
            },
          },
          paragraph: {
            HTMLAttributes: {
              class: 'font-normal',
            },
          },
          bold: {
            HTMLAttributes: {
              class: 'font-bold',
            },
          },
          bulletList: {
            HTMLAttributes: {
              class: 'list-disc ml-4',
            },
          },
          orderedList: {
            HTMLAttributes: {
              class: 'list-decimal ml-4',
            },
          },
        }),
        Link.configure({
          HTMLAttributes: {
            class: 'text-interactive hover:underline',
          },
        }),
        // Typography,
        Underline.configure({
          HTMLAttributes: {
            class: 'text-underline',
          },
        }),
        ...(extensions || []),
      ],
      editorProps: {
        attributes: {
          class: cn('p-4 focus:outline-none font-sans children:leading-6 text-sm text-foreground', editorClassName),
        },
        ...editorProps,
      },
      injectCSS,
      ...additionalOptions,
    },
    deps,
  )

  return editor
}
export const useDescriptionEditor = (options: Partial<CustomEditorOptions>, deps?: DependencyList) => {
  function containsAtSymbol(doc: JSONContent): boolean {
    return (
      doc.type === 'doc' &&
      (doc.content?.some(
        (node) =>
          node.type === 'paragraph' &&
          node.content?.some((innerNode) => innerNode.type === 'text' && innerNode.text?.includes('@')),
      ) ||
        false)
    ) // Returns false if doc.content is undefined
  }

  const DisableEnter = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: ({ editor }) => {
          const isMentioning = containsAtSymbol(editor.getJSON())
          if (options.onEnter && !isMentioning) {
            options.onEnter({ editor })
            return true
          }

          return false
        },
      }
    },
  })

  const mentionExtensions = options.allowMentions
    ? [
        Mention.extend({
          addAttributes() {
            return {
              ...this.parent?.(),
              ['data-mention']: {
                default: null,
                parseHTML: (element: HTMLElement) => {
                  return element.getAttribute('data-mention')
                },
                renderHTML: (attributes: any) => {
                  return {
                    'data-mention': `@${attributes.label}`,
                  }
                },
              },
            }
          },
        }).configure({
          HTMLAttributes: {
            class: 'mention text-interactive hover:underline',
          },
          renderLabel({ options, node }) {
            return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`
          },
          suggestion: {
            items: ({ query }: MentionOptions['suggestion']['props']) => {
              return (
                options.mentionSuggestions?.filter((user: UserItem) => {
                  return user.name?.toLowerCase().startsWith(query.toLowerCase())
                }) || []
              )
            },
            render: () => {
              let component: ReactRenderer<MentionListRef, MentionListProps>
              let popup: any

              return {
                onStart: (props: MentionOptions['suggestion']['props']) => {
                  component = new ReactRenderer(MentionList, {
                    props,
                    editor: props.editor,
                  })

                  if (!props.clientRect) {
                    return
                  }

                  popup = tippy(document.body, {
                    getReferenceClientRect: props.clientRect as () => DOMRect,
                    appendTo: () => document.getElementById('editorcontent') || document.body,
                    content: component.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                  })
                },

                onUpdate(props: MentionOptions['suggestion']['props']) {
                  component.updateProps(props)

                  if (!props.clientRect) {
                    return
                  }

                  if (popup[0]) {
                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect,
                    })
                  }
                },

                onKeyDown(props: MentionOptions['suggestion']['props']) {
                  if (props.event.key === 'Escape') {
                    if (popup[0]) {
                      popup[0].hide()
                    }

                    return true
                  }

                  return component.ref?.onKeyDown(props)
                },

                onExit() {
                  if (popup[0]) {
                    popup[0].destroy()
                  }
                  component.destroy()
                },
              }
            },
          } as MentionOptions['suggestion'],
        }),
      ]
    : []

  const customExtensions = options.onEnter ? [DisableEnter] : []

  const editor = useTextEditor(
    {
      extensions: [
        Placeholder.configure({
          placeholder: options.placeholder ?? 'Enter description...',
        }),
        CharacterCount.configure({ limit: options.characterLimit ?? null }),
        ...mentionExtensions,
        ...customExtensions,
      ],
      ...options,
    },
    deps,
  )

  return editor
}

export { EditorContent }
