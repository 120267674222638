import { CheckIcon } from '@radix-ui/react-icons'
import { Column } from '@tanstack/react-table'

import { cn } from '../utils'
import { Button } from './Button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from './Command'
import { Icon, IconNames } from './Icon'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'


export interface DataTableFacetedFilterGroupedProps<TData, TValue> {
  column?: Column<TData, TValue>
  triggerIcon: IconNames
  title?: string
  pluralTitle?: string
  groups: {
    groupName: string
    options: {
      label: string
      value: string
      icon?: IconNames
      prepend?: JSX.Element
    }[]
  }[]
}

export function DataTableFacetedFilterGrouped<TData, TValue>({
  column,
  triggerIcon,
  title,
  pluralTitle,
  groups,
}: DataTableFacetedFilterGroupedProps<TData, TValue>) {
  const facets = column?.getFacetedUniqueValues()
  const selectedValues = new Set(column?.getFilterValue() as string[])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant={selectedValues?.size > 0 ? 'interactive' : 'outline'} className='gap-1 items-center'>
          <Icon name={triggerIcon} className='h-4 w-4' />
          <span className='flex items-center gap-1'>
            {selectedValues?.size > 0 && <span>{selectedValues.size}</span>}
            {selectedValues?.size === 1 ? title : pluralTitle ?? title}
          </span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-[320px] p-0' align='start'>
        <Command>
          <CommandInput placeholder={`Search for ${title?.toLowerCase()}`} />
          <CommandList>
            <CommandEmpty>No results found.</CommandEmpty>
            {groups.map((group) => (
              <CommandGroup key={group.groupName} heading={group.groupName}>
                {group.options.map((option) => {
                  const isSelected = selectedValues.has(option.value)
                  const prependElement = option.prepend ?? null

                  return (
                    <CommandItem
                      key={option.value}
                      onSelect={() => {
                        if (isSelected) {
                          selectedValues.delete(option.value)
                        } else {
                          selectedValues.add(option.value)
                        }
                        const filterValues = Array.from(selectedValues)
                        column?.setFilterValue(filterValues.length ? filterValues : undefined)
                      }}
                    >
                      <div
                        className={cn(
                          'mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary',
                          isSelected ? 'bg-primary text-primary-foreground' : 'opacity-50 [&_svg]:invisible',
                        )}
                      >
                        <CheckIcon className={cn('h-4 w-4')} />
                      </div>
                      <div className='flex items-center gap-1'>
                        {prependElement}
                        {option.icon && <Icon name={option.icon} />}
                        <span>{option.label}</span>
                      </div>
                      {facets?.get(option.value) && (
                        <span className='ml-auto flex h-4 w-4 items-center justify-center font-mono text-xs'>
                          {facets.get(option.value)}
                        </span>
                      )}
                    </CommandItem>
                  )
                })}
              </CommandGroup>
            ))}
            {selectedValues.size > 0 && (
              <>
                <CommandSeparator />
                <CommandGroup>
                  <CommandItem
                    onSelect={() => column?.setFilterValue(undefined)}
                    className='justify-center text-center'
                  >
                    Clear filters
                  </CommandItem>
                </CommandGroup>
              </>
            )}
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  )
}
