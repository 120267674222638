import { cva, type VariantProps } from 'class-variance-authority'
import * as React from 'react'

import { X } from '@phosphor-icons/react'
import { cn } from '../utils'
import { IconButton } from './IconButton'

const basicVariants = {
  primary: 'border-transparent bg-primary text-primary-foreground shadow hover:bg-muted-foreground',
  secondary: 'border-transparent bg-secondary text-secondary-foreground hover:bg-secondary',
  plain: 'border-transparent shadow',
  outline: 'text-foreground',
  warning: 'border-transparent bg-warning text-warning-foreground shadow hover:bg-warning-muted',
  destructive: 'border-transparent bg-destructive text-destructive-foreground shadow hover:bg-destructive-muted',
  success: 'border-transparent bg-success text-success-foreground',
  interactive: 'border-transparent bg-interactive text-interactive-foreground hover:bg-interactive-muted',
  info: 'border-transparent bg-info text-info-foreground hover:bg-info-muted',
  blank: 'border-none shadow-none p-0 py-0 px-0',
  ghost: 'text-muted-foreground bg-muted border-none',
}

export const colorVariants = {
  purple: 'border-purple-500 bg-purple-300 text-purple-1100 hover:bg-purple-400 hover:border-purple-600',
  orange: 'border-orange-500 bg-orange-300 text-orange-1100 hover:bg-orange-400 hover:border-orange-600',
  sage: 'border-sage-500 bg-sage-300 text-sage-1100 hover:bg-sage-400 hover:border-sage-600',
  amber: 'border-amber-500 bg-amber-300 text-amber-1100 hover:bg-amber-400 hover:border-amber-600',
  indigo: 'border-indigo-500 bg-indigo-300 text-indigo-1100 hover:bg-indigo-400 hover:border-indigo-600',
  teal: 'border-teal-500 bg-teal-300 text-teal-1100 hover:bg-teal-400 hover:border-teal-600',
  sky: 'border-sky-500 bg-sky-300 text-sky-1100 hover:bg-sky-400 hover:border-sky-600',
  red: 'border-red-500 bg-red-300 text-red-1100 hover:bg-red-400 hover:border-red-600',
  green: 'border-green-500 bg-green-300 text-green-1100 hover:bg-green-400 hover:border-green-600',
  jade: 'border-jade-500 bg-jade-300 text-jade-1100 hover:bg-jade-400 hover:border-jade-600',
  cyan: 'border-cyan-500 bg-cyan-300 text-cyan-1100 hover:bg-cyan-400 hover:border-cyan-600',
  yellow: 'border-yellow-500 bg-yellow-300 text-yellow-1100 hover:bg-yellow-400 hover:border-yellow-600',
  brown: 'border-brown-500 bg-brown-300 text-brown-1100 hover:bg-brown-400 hover:border-brown-600',
  gray: 'border-gray-500 bg-gray-300 text-gray-1100 hover:bg-gray-400 hover:border-gray-600',
  olive: 'bg-olive-300 text-olive-1200 hover:bg-olive-400',
}

export const outlineVariants = {
  outlineWarning: 'border-warning text-warning hover:border-warning-muted',
  outlineDestructive: 'border-destructive text-destructive hover:border-destructive-muted',
  outlineSuccess: 'border-success text-success hover:border-success-muted',
  outlineInteractive: 'border-interactive text-interactive hover:border-interactive-muted',
  outlineInfo: 'border-info text-info hover:border-info-muted',
  outlinePurple: 'bg-transparent border-purple-300 text-purple-1100 hover:border-purple-400',
  outlineOrange: 'bg-transparent border-orange-300 text-orange-1100 hover:border-orange-400',
  outlineSage: 'bg-transparent border-sage-300 text-sage-1100 hover:border-sage-400',
  outlineAmber: 'bg-transparent border-amber-300 text-amber-1100 hover:border-amber-400',
  outlineIndigo: 'bg-transparent border-indigo-300 text-indigo-1100 hover:border-indigo-400',
  outlineTeal: 'bg-transparent border-teal-300 text-teal-1100 hover:border-teal-400',
  outlineSky: 'bg-transparent border-sky-300 text-sky-1100 hover:border-sky-400',
  outlineRed: 'bg-transparent border-red-300 text-red-1100 hover:border-red-400',
  outlineGreen: 'bg-transparent border-green-300 text-green-1100 hover:border-green-400',
  outlineJade: 'bg-transparent border-jade-300 text-jade-1100 hover:border-jade-400',
  outlineCyan: 'bg-transparent border-cyan-300 text-cyan-1100 hover:border-cyan-400',
  outlineYellow: 'bg-transparent border-yellow-300 text-yellow-1100 hover:border-yellow-400',
  outlineBrown: 'bg-transparent border-brown-300 text-brown-1100 hover:border-brown-400',
  outlineGray: 'bg-transparent border-gray-300 text-gray-1100 hover:border-gray-400',
  outlineOlive: 'bg-transparent border-olive-300 text-olive-1200 hover:border-olive-400',
  outlineBadge: 'border-border-badge text-badge border-[0.25px] bg-background-badge border-opacity-25',
  outlineOverdue: 'bg-transparent border-border-overdue text-overdue',
  outlineCompleted: 'bg-transparent border-border-complete text-completed',
  outlineInProgress: 'bg-transparent border-border-in-progress text-in-progress',
  outlineHealthy: ' bg-transparent border-border-healthy text-healthy',
  outlineIssues: 'bg-transparent border-border-issues text-issues',
}

const badgeVariants = cva(
  [
    'inline-flex',
    'items-center',
    'rounded-full',
    'border',
    'px-2.5',
    'py-0.5',
    'transition-colors',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-ring',
    'focus:ring-offset-2',
    'leading-[20px]',
    'text-[13px]',
    'font-light',
  ],
  {
    variants: {
      variant: {
        ...basicVariants,
        ...colorVariants,
        ...outlineVariants,
      },
      dismissable: {
        true: 'pr-0.5',
      },
      truncate: {
        true: 'truncate',
      },
      circle: {
        true: 'rounded-full p-1.5 text-md',
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)

export type BadgeVariants = VariantProps<typeof badgeVariants>

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, BadgeVariants {
  onDismiss?: () => void
}

function Badge({ className, variant, dismissable, circle, onDismiss, children, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant, dismissable, circle }), className)} {...props}>
      {children}
      {dismissable && (
        <IconButton variant='ghost' size='xs' onClick={onDismiss}>
          <X />
        </IconButton>
      )}
    </div>
  )
}

export { Badge, badgeVariants }
