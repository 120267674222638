import { AssetFileType, AssetItem, AssetUrlType } from '@thenarrative/common'
import { IconNames } from '../components'

export const assetUrlTypeLogos: Record<AssetUrlType, IconNames> = {
  [AssetUrlType.GOOGLE_DRIVE]: 'GoogleDriveLogo',
  [AssetUrlType.GOOGLE_DOCS]: 'Return',
}

export const assetFileTypeLogos: Record<AssetFileType, IconNames> = {
  [AssetFileType.PDF]: 'PDFLogo',
  [AssetFileType.MICROSOFT_WORD]: 'MicrosoftWordLogo',
  [AssetFileType.IMAGE]: 'Image',
}

const GOOGLE_DRIVE_REGEX = /^https:\/\/([\w\.-]+\.)?drive\.google\.com\/(?:drive\/folders\/|file\/d\/)([a-zA-Z0-9_-]+)/
const GOOGLE_DOCS_REGEX = /^https:\/\/docs\.google\.com\/[a-zA-Z0-9_\-\/]+/

export const getAssetUrlType = (url: string): AssetUrlType | null => {
  switch (true) {
    case GOOGLE_DRIVE_REGEX.test(url):
      return AssetUrlType.GOOGLE_DRIVE

    case GOOGLE_DOCS_REGEX.test(url):
      return AssetUrlType.GOOGLE_DOCS

    default:
      return null
  }
}

export const getAssetFileType = (filename: string) => {
  // get the file extension
  const extension = filename.split('.').pop()?.toLowerCase().trim()

  switch (extension) {
    case 'doc':
    case 'docx':
      return AssetFileType.MICROSOFT_WORD
    case 'pdf':
      return AssetFileType.PDF
    case 'png':
    case 'jpg':
    case 'jpeg':
      return AssetFileType.IMAGE
    default:
      return null
  }
}

export const getAssetIconName = (assetItem: AssetItem) => {
  if (assetItem.type === 'file' && assetItem.file) {
    const fileType = getAssetFileType(assetItem.file.name)
    if (!fileType) return
    return assetFileTypeLogos[fileType]
  }

  if (assetItem.type === 'url' && assetItem.url) {
    const assetType = getAssetUrlType(assetItem.url)
    if (!assetType) return
    return assetUrlTypeLogos[assetType]
  }

  return undefined
}
