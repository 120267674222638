import { HEADERS } from '@thenarrative/common'
import { request, Variables } from 'graphql-request'

export type Fetcher<TVariables> = {
  baseURL: string
  query: string
  variables: TVariables
  accessToken: string | undefined
  organizationId?: string
}

export async function fetcher<TResult, TVariables>({
  baseURL,
  query,
  variables,
  accessToken,
  organizationId,
}: Fetcher<TVariables>): Promise<TResult> {
  return request(baseURL, query, variables as Variables, {
    'Content-Type': 'application/json',
    ...(accessToken
      ? {
          authorization: `Bearer ${accessToken}`,
        }
      : {}),
    ...(organizationId
      ? {
          [HEADERS.ORGANIZATION_ID]: organizationId,
        }
      : {}),
  })
}
