export enum US_STATES {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export const stateMapping: [string, US_STATES][] = [
  ['Alabama', US_STATES.AL],
  ['Alaska', US_STATES.AK],
  ['American Samoa', US_STATES.AS],
  ['Arizona', US_STATES.AZ],
  ['Arkansas', US_STATES.AR],
  ['California', US_STATES.CA],
  ['Colorado', US_STATES.CO],
  ['Connecticut', US_STATES.CT],
  ['Delaware', US_STATES.DE],
  ['District Of Columbia', US_STATES.DC],
  ['Florida', US_STATES.FL],
  ['Georgia', US_STATES.GA],
  ['Guam', US_STATES.GU],
  ['Hawaii', US_STATES.HI],
  ['Idaho', US_STATES.ID],
  ['Illinois', US_STATES.IL],
  ['Indiana', US_STATES.IN],
  ['Iowa', US_STATES.IA],
  ['Kansas', US_STATES.KS],
  ['Kentucky', US_STATES.KY],
  ['Louisiana', US_STATES.LA],
  ['Maine', US_STATES.ME],
  ['Marshall Islands', US_STATES.MH],
  ['Maryland', US_STATES.MD],
  ['Massachusetts', US_STATES.MA],
  ['Michigan', US_STATES.MI],
  ['Minnesota', US_STATES.MN],
  ['Mississippi', US_STATES.MS],
  ['Missouri', US_STATES.MO],
  ['Montana', US_STATES.MT],
  ['Nebraska', US_STATES.NE],
  ['Nevada', US_STATES.NV],
  ['New Hampshire', US_STATES.NH],
  ['New Jersey', US_STATES.NJ],
  ['New Mexico', US_STATES.NM],
  ['New York', US_STATES.NY],
  ['North Carolina', US_STATES.NC],
  ['North Dakota', US_STATES.ND],
  ['Ohio', US_STATES.OH],
  ['Oklahoma', US_STATES.OK],
  ['Oregon', US_STATES.OR],
  ['Pennsylvania', US_STATES.PA],
  ['Puerto Rico', US_STATES.PR],
  ['Rhode Island', US_STATES.RI],
  ['South Carolina', US_STATES.SC],
  ['South Dakota', US_STATES.SD],
  ['Tennessee', US_STATES.TN],
  ['Texas', US_STATES.TX],
  ['US Virgin Islands', US_STATES.VI],
  ['Utah', US_STATES.UT],
  ['Vermont', US_STATES.VT],
  ['Virginia', US_STATES.VA],
  ['Washington', US_STATES.WA],
  ['West Virginia', US_STATES.WV],
  ['Wisconsin', US_STATES.WI],
  ['Wyoming', US_STATES.WY],
]

/**
 * Converts between full state names to their abbreviation enum
 * For example, 'New York' to US_STATE.NY
 * @returns a US_STATE or undefined
 */
export const convertStateToAbbr = (state: string): US_STATES | undefined => {
  const textState = state.replace(/\w\S*/g, function (text: string) {
    return text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  })

  for (let i = 0; i < stateMapping.length; i++) {
    if (stateMapping[i][0] === textState) {
      return stateMapping[i][1]
    }
  }

  return undefined
}

/**
 * Converts between state abbr enum to full state name
 * For example, US_STATE.NY to 'New York'
 * @returns a string or undefined
 */
export const convertAbbrToState = (state: US_STATES): string | undefined => {
  for (let i = 0; i < stateMapping.length; i++) {
    if (stateMapping[i][1] === state) {
      return stateMapping[i][0]
    }
  }

  return undefined
}
