import * as SelectPrimitive from '@radix-ui/react-select'
import { Check } from 'lucide-react'
import * as React from 'react'

import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils'
import { Icon, IconNames } from './Icon'
import { Text } from './typography'

const Select = SelectPrimitive.Root

const SelectGroup = SelectPrimitive.Group

const SelectValue = SelectPrimitive.Value

const triggerVariants = cva(
  [
    'flex',
    'gap-2',
    'items-center',
    'justify-between',
    'rounded-md',
    'bg-background',
    'px-3',
    'py-2',
    'text-caption',
    'ring-offset-background',
    'placeholder:text-muted-foreground',
    'placeholder:font-normal',
    'hover:bg-muted',
    'focus:outline-none',
    'focus:ring-2',
    'focus:ring-input-ring',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
  ],
  {
    variants: {
      variant: {
        input:
          'border border-input hover:[&:not(:focus-within)]:border-input-hover focus:border-input-active shadow-input',
        ghost: 'border-none',
        plain: 'border-none bg-transparent shadow-none hover:bg-background-3',
      },
      size: {
        xl: 'h-input-xl text sm',
        lg: 'h-input-lg text sm',
        md: 'h-input-md text-sm',
        sm: 'h-input-sm text-xs px-2',
      },
      width: {
        full: 'w-full',
        fit: 'w-fit',
      },
    },
    defaultVariants: {
      size: 'md',
      variant: 'input',
      width: 'full',
    },
  },
)

type TriggerProps = {
  icon?: boolean
  iconVariant?: Extract<IconNames, 'ChevronDown' | 'ChevronsUpDown'>
} & VariantProps<typeof triggerVariants> &
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, TriggerProps>(
  ({ variant, size, className, width, children, icon = true, iconVariant = 'ChevronDown', ...props }, ref) => (
    <SelectPrimitive.Trigger ref={ref} className={cn(triggerVariants({ variant, size, width }), className)} {...props}>
      {children}
      {icon && (
        <SelectPrimitive.Icon>
          <Icon name={iconVariant} className='h-4 w-4 opacity-50' />
        </SelectPrimitive.Icon>
      )}
    </SelectPrimitive.Trigger>
  ),
)
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-50 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className,
      )}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
))
SelectContent.displayName = SelectPrimitive.Content.displayName

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label ref={ref} className={cn('py-1.5 pl-8 pr-2 text-sm font-semibold', className)} {...props} />
))
SelectLabel.displayName = SelectPrimitive.Label.displayName

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      [
        'relative',
        'flex',
        'flex-row',
        'gap-2',
        'w-full',
        'cursor-default',
        'select-none',
        'items-center',
        'rounded-sm',
        'py-1.5',
        'px-2',
        'text-sm',
        'outline-none',
        'focus:bg-accent',
        'focus:text-accent-foreground',
        'hover:bg-background-2',
        'data-[disabled]:pointer-events-none',
        'data-[disabled]:opacity-50',
      ],
      className,
    )}
    {...props}
  >
    <span className='shrink-0 flex h-3.5 w-3.5 items-center justify-center'>
      <SelectPrimitive.ItemIndicator>
        <Check className='h-3.5 w-3.5' />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText className='flex gap-2 items-center grow w-full' asChild>
      <Text size='inherit'>{children}</Text>
    </SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
))
SelectItem.displayName = SelectPrimitive.Item.displayName

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator ref={ref} className={cn('-mx-1 my-1 h-px bg-muted', className)} {...props} />
))
SelectSeparator.displayName = SelectPrimitive.Separator.displayName

export { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectSeparator, SelectTrigger, SelectValue }
