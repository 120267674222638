import { Editor, BubbleMenu as TiptapBubbleMenu } from '@tiptap/react'
import { Button } from './Button'
import { Icon } from './Icon'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@radix-ui/react-popover'
import { useState } from 'react'
import { LinkInput } from './FormatBar'

export type BubbleMenuProps = {
  editor: Editor | null
}

export const BubbleMenu = ({ editor }: BubbleMenuProps) => {
  if (!editor) {
    return null
  }
  const [isLinkPopoverOpen, setIsLinkPopoverOpen] = useState(false)

  const handleLinkPopoverChange = (open: boolean) => {
    setIsLinkPopoverOpen(open)
  }

  return (
    <TiptapBubbleMenu className="border-l-0" editor={editor}>
      <div className="p-0.5 flex gap-1 border-border rounded-lg bg-white shadow">
        <Button
          onClick={() => editor?.chain().focus().toggleBold().run()}
          variant={'ghost'}
          className={`w-9 h-9 ${editor.isActive('bold') && 'bg-gray-100'}`}
          size={'icon'}
        >
          <Icon name="TextBolder" />
        </Button>

        <Button
          onClick={() => editor?.chain().focus().toggleItalic().run()}
          variant={'ghost'}
          size={'icon'}
          className={`w-9 h-9 ${editor.isActive('italic') && 'bg-gray-100'}`}
        >
          <Icon name="TextItalic" />
        </Button>

        <Button
          onClick={() => editor?.chain().focus().toggleUnderline().run()}
          variant={'ghost'}
          size={'icon'}
          className={`w-9 h-9 ${editor.isActive('underline') && 'bg-gray-100'}`}
        >
          <Icon name="TextUnderline" />
        </Button>

        <Button
          onClick={() => editor?.chain().focus().toggleStrike().run()}
          variant={'ghost'}
          size={'icon'}
          className={`w-9 h-9 ${editor.isActive('strike') && 'bg-gray-100'}`}
        >
          <Icon name="TextStrikethrough" />
        </Button>

        <Popover
          onOpenChange={handleLinkPopoverChange}
          open={isLinkPopoverOpen}
        >
          <PopoverTrigger asChild>
            <Button
              size={'icon'}
              variant={'ghost'}
              className={`w-9 h-9 ${
                editor.isActive('link') ? 'bg-gray-100' : ''
              }`}
            >
              <Icon name="TextLink" />
            </Button>
          </PopoverTrigger>
          <PopoverContent side="top" sideOffset={8} align="start">
            <LinkInput editor={editor} />
          </PopoverContent>
        </Popover>

        <Button
          onClick={() =>
            editor?.chain().focus().extendMarkRange('link').unsetLink().run()
          }
          variant={'ghost'}
          size={'icon'}
          className={`w-9 h-9`}
        >
          <Icon name="TextUnlink" />
        </Button>
      </div>
    </TiptapBubbleMenu>
  )
}

export default BubbleMenu
