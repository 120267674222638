import { RowData, Table } from '@tanstack/react-table'
import { useKeyPress } from '../hooks'
import { Button } from './Button'
import { PhosphorIcon } from './PhosphorIcon'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from './Select'
import { Flexbox } from './layout'
import { Text } from './typography'

type Props<T extends RowData> = {
  table: Table<T>
  className?: string
}

const PER_PAGE_VALUES = [5, 10, 20, 50]

export const DataTablePagination = <T extends RowData>({ table, className }: Props<T>) => {
  const totalItems = table.getState().columnFilters.length
    ? table.getFilteredRowModel().rows.length
    : table.getRowCount()

  const pagination = table.getState().pagination
  const numberOfPages = Math.ceil(totalItems / pagination.pageSize)

  useKeyPress('ArrowLeft', {
    callback: () => {
      if (table.getCanPreviousPage()) {
        table.previousPage()
      }
    },
  })

  useKeyPress('ArrowRight', {
    callback: () => {
      if (table.getCanNextPage()) {
        table.nextPage()
      }
    },
  })

  return (
    <Flexbox width='full' justify='between' className={className}>
      <Flexbox align='center' justify='end'>
        <Text size='sm' weight='semibold' color='mutedForeground'>
          {totalItems} {totalItems === 1 ? 'Item' : 'Items'}
        </Text>
      </Flexbox>
      <Flexbox align='center' gap={4}>
        <Flexbox gap={2}>
          <Flexbox align='center'>
            <Text size='sm' color='mutedForeground'>
              Per Page
            </Text>
          </Flexbox>
          <Flexbox align='center' gap={2}>
            <Select
              value={pagination.pageSize.toString()}
              onValueChange={(pageSize) => table.setPageSize(parseInt(pageSize))}
            >
              <SelectTrigger variant='input' width='full' icon={false}>
                <SelectValue>{pagination.pageSize}</SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  {PER_PAGE_VALUES.map((perPage) => {
                    return (
                      <SelectItem key={perPage} value={perPage.toString()}>
                        <Flexbox align='center'>{perPage}</Flexbox>
                      </SelectItem>
                    )
                  })}
                </SelectGroup>
              </SelectContent>
            </Select>
          </Flexbox>
        </Flexbox>
        {numberOfPages > 0 && (
          <Flexbox align='center'>
            <Text size='sm' color='mutedForeground'>
              Page {pagination.pageIndex + 1} of {numberOfPages}
            </Text>
          </Flexbox>
        )}
        <Flexbox>
          <Button
            className='rounded-full'
            variant='ghost'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <PhosphorIcon name='CaretLeft' />
          </Button>
          <Button
            className='rounded-full'
            variant='ghost'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <PhosphorIcon name='CaretRight' />
          </Button>
        </Flexbox>
      </Flexbox>
    </Flexbox>
  )
}
