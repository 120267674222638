import { IconColor } from '../enums'

export const icons = [
  'AddressBook',
  'Airplane',
  'AirplaneTilt',
  'Anchor',
  'Armchair',
  'Axe',
  'BabyCarriage',
  'Backpack',
  'Bag',
  'BagSimple',
  'Balloon',
  'Bank',
  'Barn',
  'BaseballCap',
  'Basket',
  'Basketball',
  'Bathtub',
  'Beanie',
  'BeerBottle',
  'Binoculars',
  'Boot',
  'BowlSteam',
  'Brandy',
  'Bread',
  'Bridge',
  'BriefcaseMetal',
  'Broom',
  'Building',
  'BuildingApartment',
  'BuildingOffice',
  'Buildings',
  'Cake',
  'Calculator',
  'CallBell',
  'Camera',
  'Cardholder',
  'CashRegister',
  'Chair',
  'ChefHat',
  'Cherries',
  'City',
  'ClipboardText',
  'Codesandboxlogo',
  'Coffee',
  'Coin',
  'Compass',
  'Confetti',
  'Couch',
  'CreditCard',
  'Crown',
  'CubeTransparent',
  'Database',
  'Dresser',
  'Envelope',
  'EnvelopeOpen',
  'EnvelopeSimpleOpen',
  'Fish',
  'Flashlight',
  'Flask',
  'FlyingSaucer',
  'Football',
  'Garage',
  'Gavel',
  'Gift',
  'GitFork',
  'Globe',
  'GlobeSimple',
  'GraduationCap',
  'Grains',
  'GridFour',
  'Guitar',
  'HairDryer',
  'Hammer',
  'Handbag',
  'HardHat',
  'IceCream',
  'JarLabel',
  'Kanban',
  'Lectern',
  'Lightning',
  'MagicWand',
  'MapTrifold',
  'Martini',
  'Medal',
  'MedalMilitary',
  'MegaphoneSimple',
  'OfficeChair',
  'OrangeSlice',
  'Package',
  'PaintBrushBroad',
  'PaintBrushHousehold',
  'Palette',
  'Parachute',
  'PiggyBank',
  'PixLogo',
  'Plug',
  'PlugsConnected',
  'Popcorn',
  'PottedPlant',
  'PresentationChart',
  'Printer',
  'ProjectorScreenChart',
  'PushPin',
  'Ranking',
  'Scales',
  'ShirtFolded',
  'ShoppingBagOpen',
  'ShoppingCart',
  'Sneaker',
  'SneakerMove',
  'SoccerBall',
  'SpinnerBall',
  'Stack',
  'Star',
  'StarAndCrescent',
  'Student',
  'Tag',
  'Target',
  'Taxi',
  'TeaBag',
  'TennisBall',
  'Toolbox',
  'Tote',
  'TrafficCone',
  'TreeView',
  'Trophy',
  'TShirt',
  'Umbrella',
  'Volleyball',
  'Wallet',
  'Wrench',
]

export const iconColors = Object.values(IconColor)

function getRandomElement<T>(array: T[]): T {
  const index = Math.floor(Math.random() * array.length)
  return array[index]
}

function getRandomIcon(): { icon: string; iconColor: string } {
  return {
    icon: getRandomElement(icons),
    iconColor: getRandomElement(iconColors),
  }
}

function getRandomColorClassName(): string {
  const random = getRandomElement(iconColors)

  return `bg-${random}-500`
}

export { getRandomColorClassName, getRandomIcon }
