import * as React from 'react'

import { VariantProps, cva } from 'class-variance-authority'
import { cn } from '../utils'

const variants = cva(
  'flex min-h-[60px] w-full rounded-md  shadow-input bg-transparent px-3 py-2 text-sm placeholder:text-muted-foreground placeholder:font-normal disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring border border-input',
        ghost:
          'bg-transparent border-none shadow-none focus-visible:border-none focus-visible:ring-0 focus-visible:outline-none',
      },
      autogrowing: {
        true: ['h-fit', 'resize-none', 'max-h-52', 'overflow-y-auto'],
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & VariantProps<typeof variants>

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ variant, autogrowing, className, ...props }, ref) => {
    return <textarea className={cn(variants({ variant, autogrowing }), className)} ref={ref} {...props} />
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
