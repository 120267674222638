import { CSSProperties, ForwardRefRenderFunction, HTMLProps, forwardRef, useMemo } from 'react'

type ButtonSize = 'sm' | 'md' | 'lg'

export interface LetterButtonProps extends Omit<HTMLProps<HTMLButtonElement>, 'children' | 'size' | 'type'> {
  content: string
  size?: ButtonSize
}

// gpt ftw :)
// Maps to only "pleasant" colors, avoids red / yellow
const mapCharToHue = (charCode: number): number => {
  const ranges = [
    [180, 240], // Blue range
    [90, 150], // Green range
    [240, 270], // Purple range
  ]
  const rangeIndex = charCode % ranges.length
  const [minHue, maxHue] = ranges[rangeIndex]
  const hueRange = maxHue - minHue
  return ((charCode * 137) % hueRange) + minHue // Using a prime number for distribution
}

// Function to generate a visually appealing color that is not red
const generateColor = (letter: string): number => {
  const charCode = letter.charCodeAt(0)
  const hue = mapCharToHue(charCode)
  return hue
}

const LetterButtonComponent: ForwardRefRenderFunction<HTMLButtonElement, LetterButtonProps> = (
  { content: children, size = 'md', ...props },
  ref,
) => {
  // Memoize color generation to avoid recalculating on each render
  const colorStyle: CSSProperties = useMemo(() => {
    const color = generateColor(children[0])

    return {
      backgroundColor: `hsl(${color}, 78%, 89%, 1)`,
      color: `hsl(${color}, 43%, 48%, 1)`,
    }
  }, [children])

  // Calculate styles based on size
  const style: CSSProperties = useMemo(() => {
    // Double the padding + font size for each size
    const widths = ['calc(8px + 0.675rem)', 'calc(12px + 0.75rem)', 'calc(16px + 1rem)']

    const sizeStyles = {
      sm: { padding: '4px', maxWidth: widths[0], width: widths[0], fontSize: '0.675rem', lineHeight: '0.675rem' },
      md: { padding: '6px', maxWidth: widths[1], width: widths[1], fontSize: '0.75rem', lineHeight: '0.75rem' },
      lg: { padding: '8px', maxWidth: widths[2], width: widths[2], fontSize: '1rem', lineHeight: '1rem' },
    } satisfies Record<ButtonSize, CSSProperties>

    return {
      cursor: 'unset',
      border: 'none',
      borderRadius: '6px',

      ...props?.style,
      ...colorStyle,
      ...sizeStyles[size],
    }
  }, [props?.style, colorStyle, size])

  return (
    <button ref={ref} {...props} style={style}>
      {children}
    </button>
  )
}

export const LetterButton = forwardRef(LetterButtonComponent)
