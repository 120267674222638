import { Node } from '@tiptap/core'
import BlockQuote from '@tiptap/extension-blockquote'
import Bold from '@tiptap/extension-bold'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import Color from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import DropCursor from '@tiptap/extension-dropcursor'
import HardBreak from '@tiptap/extension-hard-break'
import Heading from '@tiptap/extension-heading'
import Highlight from '@tiptap/extension-highlight'
import HorizontalRule from '@tiptap/extension-horizontal-rule'
import Italic from '@tiptap/extension-italic'
import Link from '@tiptap/extension-link'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import Paragraph from '@tiptap/extension-paragraph'
import Strike from '@tiptap/extension-strike'
import Subscript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Text from '@tiptap/extension-text'
import Typography from '@tiptap/extension-typography'
import Underline from '@tiptap/extension-underline'

// Not included for now, subject to change
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'

/**
 * @module extensions is a cross compatible library & single source of truth for how we process
 *         HTML for tiptap.
 */

type TagName = keyof HTMLElementTagNameMap

const tags = ['a', 'button', 'p', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'strong', 'style'] satisfies TagName[]

export const renderHTMLForAttribute = (attribute: string) => {
  return (attributes: Record<string, any>) => {
    return {
      [attribute]: attributes[attribute],
    }
  }
}

const getAttrs = (node: string | HTMLElement) => {
  // To be cross compatible, we need to check if the node has the getAttribute method instead of instanceof HTMLElement
  if (typeof node === 'string' || !('getAttribute' in node)) {
    return false
  }

  return {
    style: node.getAttribute('style'),
    class: node.getAttribute('class'),
  }
}

export const includeAttributes = (attributes: string[]) => {
  return {
    addAttributes() {
      return attributes.reduce((attrs, attribute) => {
        return {
          ...attrs,
          [attribute]: renderHTMLForAttribute(attribute),
        }
      }, {})
    },
  }
}

export const getCoreExtensions = () => [
  ...tags.map((tag) => {
    return Node.create({
      name: tag,

      priority: 10000000,

      group: 'block',
      content: 'inline*',

      parseHTML: () => {
        return [
          {
            tag,
            getAttrs,
          },
        ]
      },
      addAttributes() {
        return {
          style: renderHTMLForAttribute('style'),
          class: renderHTMLForAttribute('class'),
          width: renderHTMLForAttribute('width'),
          valign: renderHTMLForAttribute('valign'),
        }
      },
      renderHTML: ({ HTMLAttributes }: any) => {
        return [tag, HTMLAttributes, 0]
      },
    })
  }),
  Paragraph,
  BlockQuote,
  Bold,
  BubbleMenu,
  Color,
  Document,
  DropCursor,
  HardBreak,
  Heading.extend(includeAttributes(['style', 'class'])),
  Highlight,
  HorizontalRule,
  Italic,
  Link,
  ListItem.extend(includeAttributes(['style', 'class'])),
  OrderedList.extend(includeAttributes(['style', 'class'])),
  Strike,
  Subscript,
  Superscript,
  Table.extend(includeAttributes(['style', 'class'])),
  TableCell.extend(includeAttributes(['style', 'class'])),
  TableHeader.extend(includeAttributes(['style', 'class'])),
  TableRow.extend(includeAttributes(['style', 'class'])),
  Typography,
  TextAlign.configure({ types: ['heading', 'paragraph'] }),
  TextStyle.extend(includeAttributes(['style', 'class'])),
  Underline.configure({
    HTMLAttributes: {
      class: 'decoration-2 underline-offset-4',
    },
  }),
  Text,
]
