import { cn } from '../../utils'
import { cva, VariantProps } from 'class-variance-authority'
import { Text, TextProps } from './Text'
import { FC, PropsWithChildren } from 'react'
import { textVariants } from './Text'

const paragraph = cva([], {
  variants: {
    size: {
      xs: ['text-xs', 'leading-4'],
      sm: ['text-sm', 'leading-5'],
      md: ['text-base', 'leading-6'],
      lg: ['text-lg', 'leading-7.5'],
    },
  },
  defaultVariants: {
    size: 'md',
  },
})

export type ParagraphProps = VariantProps<typeof paragraph> &
  Pick<TextProps, 'color' | 'weight'> &
  React.HTMLAttributes<HTMLHeadingElement>

const Paragraph: FC<PropsWithChildren<ParagraphProps>> = ({
  size,
  weight,
  color,
  className,
  children,
  ...rest
}) => {
  return (
    <Text
      as="p"
      className={cn(
        paragraph({ size }),
        textVariants({ color, weight }),
        className,
      )}
      {...rest}
    >
      {children}
    </Text>
  )
}

export { Paragraph }
