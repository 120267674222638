import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '../utils'
import { Flexbox } from './layout'
import { PhosphorIcon, PhosphorIconProps } from './PhosphorIcon'

const config = cva('cursor-unset border-none', {
  variants: {
    color: {
      amber: 'bg-amber-300 text-amber-1100',
      blue: 'bg-blue-300 text-blue-1100',
      bronze: 'bg-bronze-300 text-bronze-1100',
      cyan: 'bg-cyan-300 text-cyan-1100',
      gold: 'bg-gold-300 text-gold-1100',
      green: 'bg-green-300 text-green-1100',
      indigo: 'bg-indigo-300 text-indigo-1100',
      mauve: 'bg-mauve-300 text-mauve-1100',
      mint: 'bg-mint-300 text-mint-1100',
      olive: 'bg-olive-300 text-olive-1100',
      orange: 'bg-orange-300 text-orange-1100',
      pink: 'bg-pink-300 text-pink-1100',
      plum: 'bg-plum-300 text-plum-1100',
      purple: 'bg-purple-300 text-purple-1100',
      red: 'bg-red-300 text-red-1100',
      sky: 'bg-sky-300 text-sky-1100',
      violet: 'bg-violet-300 text-violet-1100',
      yellow: 'bg-yellow-300 text-yellow-1100',
    },
    size: {
      md: 'w-6 h-6 rounded-sm text-md',
      lg: 'w-8 h-8 rounded-md text-lg',
    },
  },
})

export type TeamIconColor =
  | 'amber'
  | 'blue'
  | 'bronze'
  | 'cyan'
  | 'gold'
  | 'green'
  | 'indigo'
  | 'mauve'
  | 'mint'
  | 'olive'
  | 'orange'
  | 'pink'
  | 'plum'
  | 'purple'
  | 'red'
  | 'sky'
  | 'violet'
  | 'yellow'

type TeamIconProps = {
  color: TeamIconColor
  name: PhosphorIconProps['name']
} & VariantProps<typeof config> &
  Partial<Pick<PhosphorIconProps, 'className'>>

const TeamIcon: React.FC<TeamIconProps> = ({ color, size, name, className }) => {
  return (
    <Flexbox align='center' justify='center' className={cn(config({ color, size }), className)}>
      <PhosphorIcon name={name} />
    </Flexbox>
  )
}

export { TeamIcon }
