import { CalendarIcon } from '@radix-ui/react-icons'

import { FC, useState } from 'react'
import { cn, getNarrativeFormattedDateTime } from '../utils'
import { Button } from './Button'
import { Calendar } from './Calendar'
import { Popover, PopoverContent, PopoverTrigger } from './Popover'
import { TimePicker } from './TimePicker'

type Props = {
  date?: Date | undefined
  onChange: (date: Date | undefined) => void
  className?: string
}

export const DateTimePicker: FC<Props> = ({ date, onChange, className }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <Button
          variant={'outline'}
          className={cn(
            'w-[240px] justify-start text-left font-normal px-3',
            !date && 'text-muted-foreground',
            className,
          )}
        >
          <CalendarIcon className='mr-2 h-4 w-4' />
          {date ? getNarrativeFormattedDateTime(date.toDateString()) : <span>Pick a date and time</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='w-auto p-0' align='start'>
        <Calendar mode='single' selected={date} onSelect={onChange} initialFocus disabled={{ before: new Date() }} />
        <div className='p-3 border-t border-border'>
          <TimePicker setDate={onChange} date={date} />
        </div>
      </PopoverContent>
    </Popover>
  )
}
