import * as React from 'react'

import { isValidEmail } from '@thenarrative/common'
import { Command as CommandPrimitive } from 'cmdk'
import { FC, useCallback } from 'react'
import { cn } from '../utils'
import { Badge } from './Badge'
import { Command, CommandGroup, CommandItem } from './Command'
import { Flexbox } from './layout'

export type InputMultiSelectValue = Record<'value' | 'label', string>

type Props = {
  allowNonListValues?: boolean
  className?: string
  listValues: InputMultiSelectValue[]
  nonListPlaceholder?: string
  placeholder?: string
  selected: InputMultiSelectValue[]
  onSelectedChange: (selected: InputMultiSelectValue[], latestItem?: InputMultiSelectValue) => void
  onValueChange?: (value: string) => void
}

export const InputMultiselect: FC<Props> = ({
  allowNonListValues = false,
  className,
  listValues,
  nonListPlaceholder = '',
  placeholder = 'Select items...',
  selected,
  onSelectedChange,
  onValueChange,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const commandGroupRef = React.useRef<HTMLDivElement>(null)

  const [open, setOpen] = React.useState(false)
  const [inputValue, setInputValue] = React.useState('')

  const handleUnselect = React.useCallback(
    (item: InputMultiSelectValue) => {
      const updatedSelected = selected.filter((s) => {
        return s.value !== item.value
      })

      onSelectedChange(updatedSelected)
    },
    [onSelectedChange, selected],
  )

  const handleOnValueChange = useCallback(
    (value: string) => {
      onValueChange?.(value)
      setInputValue(value)
    },
    [onValueChange],
  )

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            const newSelected = [...selected]
            newSelected.pop()
            onSelectedChange(newSelected)
          }
        }

        if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()

          const value = input.value.trim()
          const validEmail = isValidEmail(value)

          if (!validEmail) return

          onSelectedChange([...selected, { value, label: value }])

          handleOnValueChange('')
        }

        // This is not a default behaviour of the <input /> field
        if (e.key === 'Escape') {
          input.blur()
        }
      }
    },
    [handleOnValueChange, onSelectedChange, selected],
  )

  const handleContainerClick = useCallback(() => {
    inputRef.current?.focus()
  }, [])

  const selectables = listValues.filter((listValue) => {
    return !selected.some((selectedValue) => selectedValue.value === listValue.value)
  })

  if (allowNonListValues && inputValue.length) {
    selectables.push({
      value: inputValue,
      label: `${nonListPlaceholder} ${inputValue}`,
    })
  }

  return (
    <Command onKeyDown={handleKeyDown} className='overflow-visible bg-transparent'>
      <div
        onClick={handleContainerClick}
        className={cn(
          'group flex items-center min-h-input-md p-1 text-sm leading-none border border-input rounded-md shadow-input hover:[&:not(:focus-within)]:outline-input-hover focus-within:ring-2 focus-within:ring-input-ring focus-within:outline-input-active',
          className,
        )}
      >
        <Flexbox align='center' gap={1} grow wrap>
          {selected.map((item) => {
            return (
              <Badge
                key={item.value}
                variant='outline'
                className='gap-1'
                onDismiss={() => handleUnselect(item)}
                dismissable
              >
                {item.label}
              </Badge>
            )
          })}
          <CommandPrimitive.Input
            autoFocus={false}
            ref={inputRef}
            value={inputValue}
            onValueChange={handleOnValueChange}
            onBlur={() => setOpen(false)}
            onFocus={() => setOpen(true)}
            placeholder={placeholder}
            className={cn('bg-transparent outline-none placeholder:text-muted-foreground flex-1 p-2')}
          />
        </Flexbox>
      </div>
      {open && !!selectables.length ? (
        <div className='relative'>
          <div className='absolute w-full z-10 top-1 rounded-md bg-popover text-popover-foreground outline-none animate-in'>
            <CommandGroup ref={commandGroupRef} className='h-full overflow-auto border rounded-md command-group'>
              {selectables.map((item) => {
                return (
                  <CommandItem
                    key={item.label}
                    value={`${item.label}:${item.value}`}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onSelect={() => {
                      handleOnValueChange('')
                      if (allowNonListValues && item.label.startsWith(nonListPlaceholder)) {
                        const tokens = item.label.split(`${nonListPlaceholder} `)
                        item.label = tokens[tokens.length - 1]
                      }
                      onSelectedChange([...selected, item], item)
                    }}
                    className='cursor-pointer'
                  >
                    {item.label}
                  </CommandItem>
                )
              })}
            </CommandGroup>
          </div>
        </div>
      ) : null}
    </Command>
  )
}
