import { useEffect, useRef } from 'react'

export const useDebounce = <T extends unknown[]>(callback: (...args: T) => void, delay: number) => {
  const latestCallback = useRef(callback)
  const timer = useRef<undefined | ReturnType<typeof setTimeout>>()

  useEffect(() => {
    latestCallback.current = callback
  }, [callback])

  useEffect(
    () => () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    },
    [],
  )

  return (...args: T) => {
    if (timer.current) {
      clearTimeout(timer.current)
    }
    timer.current = setTimeout(() => latestCallback.current(...args), delay)
  }
}
