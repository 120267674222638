// context/FeatureFlagContext.tsx (note the `.tsx` extension for TypeScript)
import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';

// Define the shape of the feature flags
type Flags = {
  [key: string]: boolean;
};

// Define the context type, which includes `flags` and `toggleFlag`
interface FeatureFlagContextType {
  flags: Flags;
  toggleFlag: (flagName: string) => void;
}

// Create the context with a default empty value
const FeatureFlagContext = createContext<FeatureFlagContextType | undefined>(undefined);

interface FeatureFlagProviderProps {
  children: ReactNode;
}

// Create the provider component
export const FeatureFlagProvider: React.FC<FeatureFlagProviderProps> = ({ children }) => {
  const [flags, setFlags] = useState<Flags>({});

  // Initialize flags from sessionStorage on mount
  useEffect(() => {
    const savedFlags = sessionStorage.getItem('feature_flags');
    if (savedFlags) {
      setFlags(JSON.parse(savedFlags));
    }
  }, []);

  // Function to toggle a feature flag
  const toggleFlag = (flagName: string) => {
    const newFlags = { ...flags, [flagName]: !flags[flagName] };
    setFlags(newFlags);
    // Save updated flags to sessionStorage
    sessionStorage.setItem('feature_flags', JSON.stringify(newFlags));
  };

  // Provide the flags and the toggle function
  return (
    <FeatureFlagContext.Provider value={{ flags, toggleFlag }}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

// Custom hook to use feature flags
export const useFeatureFlags = (): FeatureFlagContextType => {
  const context = useContext(FeatureFlagContext);
  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagProvider');
  }
  return context;
};
