type EnvironmentConfig = {
  API_URL: string
  GRAPHQL_API_URL: string
  CHAT_API_URL: string
  CHAT_API_KEY: string
  CLERK_PUBLISHABLE_KEY: string
  DOCX_TO_HTML_ENDPOINT_URL: string
  CKEDITOR_LICENSE_KEY: string
  SENTRY_DSN: string
  ENVIRONMENT: string
  KNOCK_PUBLIC_KEY: string
  KNOCK_FEED_CHANNEL_ID: string
  POSTHOG_KEY: string
  POSTHOG_HOST: string
  LIVEBLOCKS_API_SECRET: string
  PRIVACY_POLICY: string
  TERMS_AND_CONDITIONS: string
  SHOW_DEBUG: boolean
  // ONESCHEMA_CLIENT_ID: string
  DISPUTE_AGENT_URL: string
  LANGCHAIN_API_KEY: string
}

const throwIfUndefined = (value: string | undefined, envLabel?: string): string => {
  if (value === undefined) {
    throw new Error(`Environment variable ${envLabel ?? ''} is not defined`)
  }
  return value
}

export const ApplicationEnvironment: EnvironmentConfig = {
  API_URL: throwIfUndefined(process.env.NEXT_PUBLIC_API_URL, 'NEXT_PUBLIC_API_URL'),
  GRAPHQL_API_URL: throwIfUndefined(process.env.NEXT_PUBLIC_GRAPHQL_API_URL, 'NEXT_PUBLIC_GRAPHQL_API_URL'),
  CHAT_API_URL: throwIfUndefined(process.env.NEXT_PUBLIC_NARRATIVE_AI_ENDPOINT, 'NEXT_PUBLIC_NARRATIVE_AI_ENDPOINT'),
  CHAT_API_KEY: throwIfUndefined(process.env.NEXT_PUBLIC_NARRATIVE_AI_API_KEY, 'NEXT_PUBLIC_NARRATIVE_AI_API_KEY'),
  CLERK_PUBLISHABLE_KEY: throwIfUndefined(
    process.env.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY,
    'NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY',
  ),
  DOCX_TO_HTML_ENDPOINT_URL: throwIfUndefined(
    process.env.NEXT_PUBLIC_DOCX_TO_HTML_ENDPOINT_URL,
    'NEXT_PUBLIC_DOCX_TO_HTML_ENDPOINT_URL',
  ),
  CKEDITOR_LICENSE_KEY: throwIfUndefined(
    process.env.NEXT_PUBLIC_CKEDITOR_LICENSE_KEY,
    'NEXT_PUBLIC_CKEDITOR_LICENSE_KEY',
  ),
  SENTRY_DSN: throwIfUndefined(process.env.NEXT_PUBLIC_SENTRY_DSN, 'NEXT_PUBLIC_SENTRY_DSN'),
  ENVIRONMENT: throwIfUndefined(process.env.NEXT_PUBLIC_NODE_ENV, 'NEXT_PUBLIC_NODE_ENV'),
  KNOCK_PUBLIC_KEY: throwIfUndefined(process.env.NEXT_PUBLIC_KNOCK_PUBLIC_KEY, 'NEXT_PUBLIC_KNOCK_PUBLIC_KEY'),
  KNOCK_FEED_CHANNEL_ID: throwIfUndefined(
    process.env.NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID,
    'NEXT_PUBLIC_KNOCK_FEED_CHANNEL_ID',
  ),
  POSTHOG_KEY: throwIfUndefined(process.env.NEXT_PUBLIC_POSTHOG_KEY, 'NEXT_PUBLIC_POSTHOG_KEY'),
  POSTHOG_HOST: throwIfUndefined(process.env.NEXT_PUBLIC_POSTHOG_HOST, 'NEXT_PUBLIC_POSTHOG_HOST'),
  LIVEBLOCKS_API_SECRET: throwIfUndefined(
    process.env.NEXT_PUBLIC_LIVEBLOCKS_API_SECRET,
    'NEXT_PUBLIC_LIVEBLOCKS_API_SECRET',
  ),
  PRIVACY_POLICY: 'https://docs.google.com/document/d/1hCQRQtxl7p2EUdV9cbPHqm0zFOQnJizIUqNKfdJJIC4/edit', // "https://www.thenarrative.dev/privacy-policy",
  TERMS_AND_CONDITIONS: 'https://docs.google.com/document/d/1qf7ND2oDdLiEppWqZOI2RfB7zzhq_5Tjy7ej5yNyJ6U/edit', //"https://www.thenarrative.dev/terms-and-conditions"
  // ONESCHEMA_CLIENT_ID: throwIfUndefined(process.env.NEXT_PUBLIC_ONESCHEMA_CLIENT_ID, 'NEXT_PUBLIC_ONESCHEMA_CLIENT_ID'),
  SHOW_DEBUG: process.env.NEXT_PUBLIC_NODE_ENV !== 'production',
  DISPUTE_AGENT_URL: throwIfUndefined(
    process.env.NEXT_PUBLIC_LANGCHAIN_DISPUTE_AGENT_API_URL,
    'NEXT_PUBLIC_LANGCHAIN_DISPUTE_AGENT_API_URL',
  ),
  LANGCHAIN_API_KEY: throwIfUndefined(process.env.NEXT_PUBLIC_LANGCHAIN_API_KEY, 'NEXT_PUBLIC_LANGCHAIN_API_KEY'),
}
