import { useClerk } from '@clerk/nextjs'
import { useQueryClient } from '@tanstack/react-query'
import { GetCurrentUserQuery } from '@thenarrative/dto-types'
import { GET_CURRENT_USER } from '@thenarrative/graphql-client'
import { FullScreenSpinner } from '@thenarrative/ui'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import React, { FC, createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useAuthenticatedQuery } from '../hooks/useAuthenticatedQuery'

export type CurrentUser = GetCurrentUserQuery['me']

type Session = {
  user: CurrentUser | null
  isPending: boolean
  signOut: () => void
}

export const SessionContext = createContext<Session>({
  user: null,
  isPending: false,
  signOut: () => undefined,
})

type Props = {
  children: React.ReactNode
}

/**
 * A react context that provides the user object for the current session
 */
export const SessionProvider: FC<Props> = ({ children }) => {
  const { signOut } = useClerk()
  const router = useRouter()
  const queryClient = useQueryClient()

  const { data, isPending: isSessionPending } = useAuthenticatedQuery({
    query: GET_CURRENT_USER,
    queryKey: ['currentUser'],
  })

  useEffect(() => {
    if (data?.me) {
      const { firstName, lastName, email } = data?.me ?? {}

      posthog.identify(
        email, // Replace 'distinct_id' with your user's unique identifier
        { email: email, name: `${firstName ?? ''}${firstName ? ' ' : ''}${lastName ?? ''}` },
      )
    }
  }, [data?.me])

  const handleSignOut = useCallback(() => {
    signOut(() => {
      router.push('/sign-in')
      queryClient.clear()
      // if we want to reset the device_id so that the device will be considered a new device
      // in future events, pass true
      posthog.reset()
    })
  }, [queryClient, router, signOut])

  const value = useMemo(
    () => ({
      user: data?.me ?? null,
      isPending: isSessionPending,
      signOut: handleSignOut,
    }),
    [data?.me, isSessionPending, handleSignOut],
  )

  if (isSessionPending) {
    return <FullScreenSpinner />
  }

  return <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
}

export const useSessionContext = () => useContext(SessionContext)
