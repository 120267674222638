import { cva, VariantProps } from 'class-variance-authority'
import { FC, HTMLAttributes, ReactNode } from 'react'
import { cn } from '../../utils'
import { Flexbox } from '../layout'
import { AvatarFallback, AvatarImage, Avatar as AvatarRoot } from './primitives'

const variants = cva('text-white', {
  variants: {
    size: {
      sm: 'w-4 h-4 text-xs',
      md: 'w-6 h-6 text-sm',
      lg: 'w-8 h-8 text-md',
    },
    shape: {
      circle: 'rounded-full',
      square: 'rounded-md',
    },
  },
  defaultVariants: {
    size: 'md',
    shape: 'square',
  },
})

type AvatarProps = {
  src?: string | null
  fallback: ReactNode
  imageClassName?: string
  fallbackClassName?: string
} & VariantProps<typeof variants> &
  HTMLAttributes<HTMLSpanElement>

const Avatar: FC<AvatarProps> = ({
  src,
  fallback,
  fallbackClassName,
  imageClassName,
  shape,
  size,
  className,
  ...rest
}) => {
  return (
    <Flexbox align='center' justify='center' asChild>
      <AvatarRoot className={cn(variants({ size, shape }), className)} {...rest}>
        <AvatarImage src={src ?? undefined} draggable={false} className={className}/>
        <AvatarFallback className={fallbackClassName}>{fallback}</AvatarFallback>
      </AvatarRoot>
    </Flexbox>
  )
}

export { Avatar, type AvatarProps }
