import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'
import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area'

import { cn } from '../utils'

const ScrollArea = ScrollAreaPrimitive.Root
const ScrollAreaScrollbar = ScrollAreaPrimitive.Scrollbar

const ScrollAreaThumb = ScrollAreaPrimitive.Thumb

const ScrollAreaCorner = ScrollAreaPrimitive.Corner

const ScrollAreaViewport = forwardRef<
  ElementRef<typeof ScrollAreaPrimitive.Viewport>,
  ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Viewport>
>(({ className, ...props }, ref) => (
  <ScrollAreaPrimitive.Viewport
    ref={ref}
    className={cn('w-full h-full overflow-hidden rounded-md', className)}
    {...props}
  />
))

export {
  ScrollArea,
  ScrollAreaViewport,
  ScrollAreaScrollbar,
  ScrollAreaThumb,
  ScrollAreaCorner,
}
